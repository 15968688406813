
import { defineComponent } from "vue";
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Input from "@/components/Input.vue";
import Context from "@/layouts/Context.vue";
import Actions from "@/layouts/Actions.vue";
import Table from "@/components/Table.vue";
import Card from "@/components/Card.vue";
import ReportTabs from "./ReportTabs.vue";
import { TeamListDto } from "@/models/teams";

interface WebsiteListDto {
  domain: string;
  team: { name: string; uuid: string };
}

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Reports", to: "/reports/websites" },
];

export default defineComponent({
  name: "Report",
  components: {
    Card,
    Table,
    Breadcrumbs,
    Input,
    Context,
    Actions,
    ReportTabs,
  },
  props: ["user"],
  data() {
    return {
      loading: true,
      rows: [],
      availableWebsites: [] as WebsiteListDto[],
      availableTeams: [] as TeamListDto[],
      breadcrumbLinks: breadcrumbLinks,

      filter: {
        websiteTeam: "",
        website: "",
        dateFrom: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split("T")[0],
        dateTo: new Date().toISOString().split("T")[0],
      },
    };
  },

  computed: {
    filteredWebsites(): WebsiteListDto[] {
      return this.availableWebsites.filter((website: WebsiteListDto) => {
        if (!this.filter.websiteTeam) {
          return true;
        }
        return website.team && website.team.uuid === this.filter.websiteTeam;
      });
    },
  },

  async mounted() {
    const { data } = await axios.get<WebsiteListDto[]>("/api/websites");
    this.availableWebsites = data;
    const { data: teamData } = await axios.get("/api/teams");
    if (this.user.role == "system_admin") {
      this.availableTeams = teamData;
    } else {
      const userTeamName = this.user.team;
      const userTeam = (teamData as TeamListDto[]).filter(
        (team: TeamListDto) => team.name == userTeamName
      );
      this.availableTeams = userTeam;
      this.filter.websiteTeam = userTeam[0].uuid;
    }

    await this.loadStatistics();
  },

  watch: {
    filter: {
      handler() {
        this.loadStatistics();
      },
      deep: true,
    },
  },

  methods: {
    async loadStatistics() {
      this.loading = true;
      const { data } = await axios.get<{ statistics: [] }>(
        "/api/reports/overall",
        {
          params: {
            websiteTeam: this.filter.websiteTeam || null,
            website: this.filter.website || null,
            dateFrom: this.filter.dateFrom,
            dateTo: this.filter.dateTo,
          },
        }
      );
      this.rows = data.statistics;
      this.loading = false;
      // todo exception / error handling
    },

    calculateSubscriptionRate(
      subscriptions: number,
      visitorsWithPushSupport: number
    ) {
      if (visitorsWithPushSupport === 0) {
        return " - ";
      }
      return this.formatAsPercentage(subscriptions / visitorsWithPushSupport);
    },
    formatAsPercentage(value: number) {
      // multiply by 100 and round to 2 decimal places
      return Math.round(value * 10000) / 100 + "%";
    },
  },
});
