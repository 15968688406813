<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Add website </template>
    <template #description>
      Manage your subscribers and monetize your sales. Add a new website to
      download the NeoPush script for your website.
    </template>
  </Context>

  <Modal
    :open="showDomainAlreadyInUseModal"
    title="Domain already in use"
    icon="error-warning-line"
    @close="closeDomainAlreadyInUseModal"
  >
    <template #content>
      There is already a website using this domain. Do you still want to create
      this website?
    </template>
    <template #buttons>
      <Button @click="closeDomainAlreadyInUseModal" :disabled="saving" brand>
        Cancel
      </Button>
      <Button @click="confirmCreateAnyway" :disabled="saving">
        Create anyway
      </Button>
    </template>
  </Modal>

  <form
    @submit.prevent="submitForm(false)"
    class="
      w-full w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      gap-6
      p-6
    "
  >
    <span class="col-span-full text-sm font-bold text-gray-500"
      >Website details</span
    >

    <Card>
      <div
        class="
          p-4
          flex flex-col
          md:flex-row
          items-center
          gap-4
          text-left
          w-full
        "
      >
        <small class="block w-full md:w-1/3 text-xs font-bold text-gray-400">
          Team
        </small>
        <select v-model="websiteTeam" class="border border-gray-300 rounded">
          <option
            v-for="team in availableTeams"
            :value="team.uuid"
            :key="team.uuid"
          >
            {{ team.name }}
          </option>
        </select>
      </div>
      <div
        class="
          p-4
          flex flex-col
          md:flex-row
          items-center
          gap-4
          text-left
          w-full
        "
      >
        <small class="block w-full md:w-1/3 text-xs font-bold text-gray-400"
          >Domain</small
        >
        <Input
          v-model="websiteName"
          id="domain-input"
          :disabled="saving"
          addon-left="https://"
          inline-text
        />
      </div>

      <footer
        class="w-full p-4 gap-4 flex justify-end items-center overflow-x-auto"
      >
        <Button icon="close-line" to="/websites">Cancel</Button>
        <Button type="submit" icon="add-line" brand>Add</Button>
      </footer>
    </Card>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Breadcrumbs from "@/components/Breadcrumbs";
import Input from "@/components/Input";
import Context from "@/layouts/Context";
import Modal from "@/components/Modal";

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Websites", to: "/websites" },
  { name: "Create websites", to: "/websites/create" },
];

export default defineComponent({
  name: "CreateWebsite",
  components: { Modal, Card, Button, Breadcrumbs, Input, Context },
  data() {
    return {
      websiteName: "",
      websiteTeam: "",
      verticals: [],
      saving: false,
      verticalOptions: [],
      breadcrumbLinks: breadcrumbLinks,
      availableTeams: [],

      showDomainAlreadyInUseModal: false,
    };
  },
  async mounted() {
    this.loadVerticals();
    const { data } = await axios.get("/api/teams");
    this.availableTeams = data;
  },
  methods: {
    loadVerticals() {
      axios
        .get("/api/verticals")
        .then((response) => {
          this.verticalOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectAllVerticals() {
      this.verticals = this.verticalOptions.map((vOption) => vOption.name);
    },
    selectNoneVerticals() {
      this.verticals = [];
    },
    toggleVertical(vertical) {
      const idx = this.verticals.indexOf(vertical.name);
      if (idx === -1) {
        this.verticals.push(vertical.name);
      } else {
        this.verticals.splice(idx, 1);
      }
    },
    closeDomainAlreadyInUseModal() {
      if (this.saving) {
        return;
      }
      this.showDomainAlreadyInUseModal = false;
    },
    confirmCreateAnyway() {
      if (this.saving) {
        return;
      }
      this.showDomainAlreadyInUseModal = false;
      this.submitForm(true);
    },
    submitForm(force) {
      if (this.saving) {
        return;
      }
      if (this.websiteName.length === 0) {
        alert("Please enter a domain name");
        return;
      }
      if (this.websiteTeam.length === 0) {
        alert("Please select a team");
        return;
      }
      this.saving = true;
      axios
        .post("/api/websites", {
          domain: this.websiteName,
          team: this.websiteTeam,
          force: force,
          // verticals: this.verticals,
        })
        .then(() => {
          this.saving = false;
          this.$router.push("/websites");
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status === 400 &&
            e.response.data &&
            e.response.data.error === "domain_in_use"
          ) {
            this.saving = false;
            this.showDomainAlreadyInUseModal = true;
            return;
          }

          alert("error creating website"); // TODO
          console.log(e, e.response);
          this.saving = false;
        });
    },
  },
});
</script>

<style scoped></style>
