<template>
  <button
    v-if="!to && !href"
    :type="type"
    :disabled="disabled"
    class="button"
    @click="$emit('click', $event)"
    :class="[
      {
        basic: basic,
        brand: brand,
        danger: danger,
        loading: loading,
        disabled: disabled,
      },
      $attrs.class,
    ]"
  >
    <span v-if="badge" class="button__badge">{{ badge }}</span>
    <i v-if="icon" class="button__icon" :class="iconClass"></i>
    <span v-if="!!$slots.default" class="button__name" :class="iconOnly"
      ><slot
    /></span>
  </button>

  <a
    v-if="href && !to"
    :href="href"
    class="button"
    :target="target"
    :download="download"
    :class="[
      {
        basic: basic,
        brand: brand,
        danger: danger,
        loading: loading,
        disabled: disabled,
      },
      $attrs.class,
    ]"
  >
    <span v-if="badge" class="button__badge">{{ badge }}</span>
    <i v-if="icon" class="button__icon" :class="iconClass"></i>
    <span v-if="!!$slots.default" class="button__name" :class="iconOnly"
      ><slot
    /></span>
  </a>

  <router-link
    v-if="to"
    :to="to"
    class="button"
    :class="[
      {
        basic: basic,
        brand: brand,
        danger: danger,
        loading: loading,
        disabled: disabled,
      },
      $attrs.class,
    ]"
  >
    <span v-if="badge" class="button__badge">{{ badge }}</span>
    <i v-if="icon" class="button__icon" :class="iconClass"></i>
    <span v-if="!!$slots.default" class="button__name" :class="iconOnly"
      ><slot
    /></span>
  </router-link>
</template>

<style scoped>
.button {
  @apply relative min-w-12 min-h-12 flex-shrink-0 inline-flex items-center justify-center gap-2 px-3 border rounded-xl;
  @apply bg-gray-50 border-gray-50 text-gray-900;
  @apply hover:bg-gray-100 hover:border-gray-100;
}
.button.white {
  @apply bg-white border-white text-gray-900 hover:bg-gray-200 hover:border-gray-200;
}
.button.router-link-active {
  @apply bg-brand-600 text-white;
}
.button__badge {
  @apply w-auto py-px px-1.5 rounded absolute top-0 right-0 transform -translate-y-1 translate-x-1;
  @apply text-tiny font-medium text-white bg-red-500;
}
.button__icon {
  @apply text-2xl font-normal;
}
.button__name {
  @apply text-xs font-semibold tracking-wide;
}

.button.basic {
  @apply bg-transparent border-transparent px-0;
  @apply text-brand-500;
  @apply hover:text-brand-600 hover:underline;
}

.button.brand {
  @apply bg-brand-500 border-brand-500 text-white;
  @apply hover:bg-brand-600 hover:border-brand-600;
}

.button.danger {
  @apply bg-red-50 border-red-50 text-red-600;
  @apply hover:bg-red-500 hover:border-red-500 hover:text-white;
}

.button.disabled {
  @apply opacity-50 cursor-not-allowed;
}

.button.loading {
  @apply opacity-50 cursor-not-allowed;
}

/* .button {
  @apply inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium;
  @apply rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
}

.button.secondary {
  @apply border-gray-300 text-gray-700 bg-white hover:bg-gray-50;
}

.button.disabled {
  @apply opacity-50 cursor-not-allowed;
}

.button.loading {
  @apply opacity-50 cursor-not-allowed;
} */
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    badge: {
      type: String,
      default: null,
    },
    basic: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "button",
    },
    to: {
      type: String,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    download: {
      type: Boolean,
    },
    brand: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      return this.icon ? `ri-${this.icon}` : null;
    },
    iconOnly() {
      return this.icon ? "hidden sm:block" : null;
    },
  },
});
</script>
