
import { defineComponent } from "vue";
import Card from "@/components/Card.vue";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import axios from "axios";
import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  name: "TeamIntegrations",
  components: { Spinner, Input, Modal, Button, Card },
  props: {
    teamUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      integrationsLoading: false,
      integrations: [],

      addModalOpen: false,
      addModalError: "",
      cleverPushApiKey: "",
      addModalSaving: false,

      removeModalOpen: false,
      removeModalSelectedUuid: "",
      removeModalError: "",
      removeModalSaving: false,
    };
  },

  computed: {
    hasIntegrations() {
      return this.integrations.length > 0;
    },
  },

  mounted() {
    this.loadIntegrations();
  },

  methods: {
    integrationNameForType(type: string) {
      switch (type) {
        case "cleverpush":
          return "CleverPush";
        default:
          return type;
      }
    },

    integrationDescriptionForType(type: string) {
      switch (type) {
        case "cleverpush":
          return "This integration allows you to create campaigns from within NeoPush and sync them to CleverPush.";
        default:
          return "";
      }
    },

    formatDate(date: string) {
      return new Date(date).toLocaleDateString();
    },

    loadIntegrations() {
      this.integrationsLoading = true;
      axios
        .get(`/api/teams/${this.teamUuid}/integrations`)
        .then((response) => {
          this.integrations = response.data;
        })
        .catch(() => {
          this.hasIntegrations = false;
          this.integrations = [];
        })
        .finally(() => {
          this.integrationsLoading = false;
        });
    },

    openAddModal() {
      this.cleverPushApiKey = "";
      this.addModalError = "";
      this.addModalOpen = true;
    },
    cancelAddModal() {
      this.addModalOpen = false;
    },
    submitAddModal() {
      if (this.addModalSaving) {
        return;
      }
      if (!this.cleverPushApiKey) {
        this.addModalError = "Please enter your CleverPush API key";
        return;
      }

      this.addModalSaving = true;
      this.addModalError = "";
      axios
        .post(`/api/teams/${this.teamUuid}/integrations`, {
          type: "cleverpush",
          config: {
            apiKey: this.cleverPushApiKey,
          },
        })
        .then(() => {
          this.addModalOpen = false;
          this.loadIntegrations();
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message) {
            this.addModalError = e.response.data.message;
            return;
          }
          this.addModalError = "Error adding integration";
        })
        .finally(() => {
          this.addModalSaving = false;
        });
    },

    openRemoveModal(uuid: string) {
      this.removeModalError = "";
      this.removeModalOpen = true;
      this.removeModalSelectedUuid = uuid;
    },
    cancelRemoveModal() {
      this.removeModalOpen = false;
    },
    submitRemoveModal() {
      if (this.removeModalSaving) {
        return;
      }

      this.removeModalSaving = true;
      this.removeModalError = "";
      axios
        .delete(
          `/api/teams/${this.teamUuid}/integrations/${this.removeModalSelectedUuid}`
        )
        .then(() => {
          this.removeModalOpen = false;
          this.loadIntegrations();
        })
        .catch(() => {
          this.removeModalError = "Error removing integration";
        })
        .finally(() => {
          this.removeModalSaving = false;
        });
    },
  },
});
