<template>
  <ul class="breadcrumbs" v-if="links">
    <template v-for="(link, index) in links" :key="index">
      <li
        class="breadcrumb__separator ri-arrow-drop-right-line"
        v-if="index > 0"
      ></li>
      <li class="breadcrumb__item">
        <router-link class="breadcrumb__link" :to="link.to">{{
          link.name
        }}</router-link>
      </li>
    </template>
  </ul>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Breadcrumbs",
  components: {},
  data() {
    return {};
  },
  props: {
    links: {
      type: Array,
      default: null,
    },
  },
  methods: {},
});
</script>

<style scoped>
.breadcrumbs {
  @apply w-full flex flex-wrap items-center;
}
.breadcrumb__separator {
  @apply text-2xl font-normal leading-none text-gray-400;
}
.breadcrumb__link {
  @apply text-xs font-semibold leading-none tracking-wide text-gray-400;
  @apply hover:underline;
}
.breadcrumb__link.router-link-exact-active {
}
</style>
