import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-sm font-medium text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_SwitchLabel = _resolveComponent("SwitchLabel")!
  const _component_SwitchGroup = _resolveComponent("SwitchGroup")!

  return (_openBlock(), _createBlock(_component_SwitchGroup, {
    as: "div",
    class: "flex items-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Switch, {
        "model-value": _ctx.modelValue,
        "onUpdate:modelValue": _ctx.updateModelValue,
        "aria-disabled": _ctx.disabled,
        class: _normalizeClass([
        _ctx.modelValue ? 'bg-brand-600' : 'bg-gray-200',
        _ctx.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500',
      ])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            "aria-hidden": "true",
            class: _normalizeClass([
          _ctx.modelValue ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ])
          }, null, 2)
        ]),
        _: 1
      }, 8, ["model-value", "onUpdate:modelValue", "aria-disabled", "class"]),
      (_ctx.hasText)
        ? (_openBlock(), _createBlock(_component_SwitchLabel, {
            key: 0,
            as: "span",
            class: "ml-3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}