<template>
  <article class="card" :class="Focus">
    <slot></slot>
  </article>
</template>

<style scoped>
.card {
  @apply w-full shadow-md rounded-xl divide-y flex flex-col justify-center;
  @apply bg-white divide-gray-50;
}
.card.brand {
  @apply bg-brand-500  text-white;
  @apply hover:bg-brand-600;
}
.card.--focus {
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Card",
  props: {
    focus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    Focus() {
      return this.focus ? "--focus" : null;
    },
  },
});
</script>
