
import { defineComponent } from "vue";
import axios from "axios";
import WebsiteNav from "@/views/Websites/WebsiteNav.vue";
import Button from "@/components/Button.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Context from "@/layouts/Context.vue";
import Actions from "@/layouts/Actions.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Websites", to: "/websites" },
];

export default defineComponent({
  name: "WebsiteDetails",

  components: {
    Input,
    Modal,
    Card,
    WebsiteNav,
    Button,
    Breadcrumbs,
    Context,
    Actions,
  },
  props: ["user"],
  data() {
    return {
      website: {} as {
        uuid: string;
        domain: string;
        createdAt: string;
        active: boolean;
        sdk_url: string;
      },
      loading: true,
      breadcrumbLinks: breadcrumbLinks,
      changeStatusModal: {
        open: false,
        newStatus: false,
        saving: false,
      },
      domainEdit: {
        active: false,
        saving: false,
        domain: "",
        duplicateDomainModalOpen: false,
      },
    };
  },

  mounted() {
    this.loadWebsite();
  },

  methods: {
    loadWebsite() {
      this.loading = true;

      if (this.breadcrumbLinks.length > 2) {
        this.breadcrumbLinks.splice(-1);
      }

      axios
        .get("/api/websites/" + this.uuid)
        .then((response) => {
          this.website = response.data;
          this.breadcrumbLinks.push({
            name: this.website.domain,
            to: "/websites/" + this.website.uuid,
          });

          this.loading = false;
        })
        .catch(() => {
          // TODO
        });
    },

    startDomainEdit() {
      this.domainEdit.active = true;
      this.domainEdit.domain = this.website.domain;
    },

    cancelDomainEdit() {
      if (this.domainEdit.saving) {
        return;
      }
      this.domainEdit.active = false;
    },

    saveDomainEdit(force: boolean) {
      if (this.domainEdit.saving) {
        return;
      }

      this.domainEdit.saving = true;
      axios
        .patch("/api/websites/" + this.uuid, {
          domain: this.domainEdit.domain,
          forceName: force,
        })
        .then(() => {
          this.domainEdit.saving = false;
          this.domainEdit.active = false;
          this.loadWebsite();
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status === 400 &&
            e.response.data &&
            e.response.data.error === "domain_in_use"
          ) {
            this.domainEdit.saving = false;
            this.domainEdit.duplicateDomainModalOpen = true;
            return;
          }

          // TODO
          this.domainEdit.saving = false;
          alert("Error saving website domain");
          console.error(e);
        });
    },

    closeDuplicateDomainModal() {
      if (this.domainEdit.saving) {
        return;
      }
      this.domainEdit.duplicateDomainModalOpen = false;
    },
    confirmUpdateDomainAnyway() {
      if (this.domainEdit.saving) {
        return;
      }
      this.domainEdit.duplicateDomainModalOpen = false;
      this.saveDomainEdit(true);
    },

    openChangeStatusModal(newStatus: boolean) {
      this.changeStatusModal.open = true;
      this.changeStatusModal.newStatus = newStatus;
    },

    closeChangeStatusModal() {
      if (!this.changeStatusModal.saving) {
        this.changeStatusModal.open = false;
      }
    },

    doEnableWebsite() {
      this.doChangeWebsiteStatus(true);
    },
    doDisableWebsite() {
      this.doChangeWebsiteStatus(false);
    },

    doChangeWebsiteStatus(newStatus: boolean) {
      if (this.changeStatusModal.saving) {
        return;
      }
      this.changeStatusModal.saving = true;

      axios
        .patch("/api/websites/" + this.uuid + "/status", {
          active: newStatus,
        })
        .then(() => {
          this.changeStatusModal.saving = false;
          this.changeStatusModal.open = false;
          this.loadWebsite();
        })
        .catch((err) => {
          // TODO
          this.changeStatusModal.saving = false;
          alert("Error saving website status");
          console.error(err);
        });
    },
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    codeSnippet() {
      if (!this.website) {
        return "";
      }
      return (
        `<script>
  window.neopush = window.neopush || [];
  neopush.push(function() {
    neopush.init('${this.website.uuid}');
  });
</` +
        `script>
<script src="${this.website.sdk_url}"><` +
        `/script>`
      );
    },
  },
});
