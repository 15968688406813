
import { defineComponent } from "vue";

import axios from "axios";
import Button from "@/components/Button.vue";
import Card from "@/components/Card.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Input from "@/components/Input.vue";
import Context from "@/layouts/Context.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import ImageWithLightbox from "@/components/ImageWithLightbox.vue";
import Spinner from "@/components/Spinner.vue";

interface WebsiteListDto {
  domain: string;
  team: { name: string; uuid: string };
}

interface CreateUpdateCampaignDto {
  name: string;

  team: string;

  type: string;

  active: boolean;

  startDate: string | null;

  endDate: string | null;

  trafficSources: {
    allWebsites: boolean;
    sameTeam: boolean;
    websites: string[];
    cleverPushChannel: string | null;
  };

  visuals: {
    uuid?: string;
    key: string;
    type: "neopush" | "pushub-api";
    active: boolean;
    title: string;
    body: string;
    url: string;

    icon?: string | null;
    image?: string | null;
    iconUrl?: string | null;
    imageUrl?: string | null;

    pushubSubId?: string | null;
  }[];

  impressionLimit: {
    enabled: boolean;
    limit: number;
    days: number;
  };

  clickLimit: {
    enabled: boolean;
    limit: number;
    days: number;
  };

  schedule: {
    enabled: boolean;
    mon: number[];
    tue: number[];
    wed: number[];
    thu: number[];
    fri: number[];
    sat: number[];
    sun: number[];
  };
}

export default defineComponent({
  name: "CampaignCreateUpdateForm",
  components: {
    Spinner,
    ImageWithLightbox,
    ToggleSwitch,
    Card,
    Button,
    Breadcrumbs,
    Input,
    Context,
    MultiSelect,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      availableTeams: [],

      availableWebsites: [] as WebsiteListDto[],
      availableWeekdays: [
        { identifier: "mon", name: "Monday" },
        { identifier: "tue", name: "Tuesday" },
        { identifier: "wed", name: "Wednesday" },
        { identifier: "thu", name: "Thursday" },
        { identifier: "fri", name: "Friday" },
        { identifier: "sat", name: "Saturday" },
        { identifier: "sun", name: "Sunday" },
      ],
      scheduleHours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],

      // every hour from 0 - 23 and half hours after that, so e.g. 00:30, 01:00, 01:30, ...
      scheduleSlots: Array.from({ length: 48 }, (_, i) => {
        const hour = Math.floor(i / 2);
        const hourString = hour < 10 ? `0${hour}` : `${hour}`;
        const minute = i % 2 === 0 ? "00" : "30";
        return `${hourString}:${minute}`;
      }),

      loading: this.mode === "update",
      saving: false,

      iconPreview: {} as Record<string, string | null>,
      iconFile: {} as Record<string, File | null>,
      imagePreview: {} as Record<string, string | null>,
      imageFile: {} as Record<string, File | null>,

      loadingIntegrationOptions: false,
      integrationOptions: {
        integrations: [] as string[],
        cleverPushChannels: [] as { id: string; name: string }[],
      },

      campaign: {
        name: "",
        type: "internal",
        team: "",
        active: false,

        startDate: null as string | null,
        endDate: null as string | null,

        trafficSources: {
          allWebsites: false,
          sameTeam: false,
          websites: [] as string[],
          cleverPushChannel: null as string | null,
        },

        visuals: [
          {
            key: this.generateKey(),
            type: "neopush",
            active: true,
            title: "",
            body: "",
            url: "",
            icon: "",
            image: "",
            pushubSubId: "",
          },
        ] as CreateUpdateCampaignDto["visuals"],

        impressionLimit: {
          enabled: false,
          limit: 100,
          days: 1,
        },
        clickLimit: {
          enabled: false,
          limit: 10,
          days: 1,
        },

        schedule: {
          enabled: false,
          mon: [] as number[],
          tue: [] as number[],
          wed: [] as number[],
          thu: [] as number[],
          fri: [] as number[],
          sat: [] as number[],
          sun: [] as number[],
        },
      },
    };
  },
  async mounted() {
    const { data } = await axios.get<WebsiteListDto[]>("/api/websites");
    // sort by team + domain for better selectability in select list
    if (data) {
      data.sort((a, b) => {
        const aCombinedKeys = (a.team ? a.team.name : "") + "_" + a.domain;
        const bCombinedKeys = (b.team ? b.team.name : "") + "_" + b.domain;
        if (aCombinedKeys > bCombinedKeys) {
          return 1;
        }
        if (aCombinedKeys < bCombinedKeys) {
          return -1;
        }
        return 0;
      });
    }
    this.availableWebsites = data;

    const { data: teamData } = await axios.get("/api/teams");
    this.availableTeams = teamData;

    if (this.mode === "update") {
      await this.loadCampaign();
    }

    this.loadIntegrationOptions();
  },
  watch: {
    uuid() {
      if (this.mode === "update") {
        this.loadCampaign();
      }
    },
    selectedTeamUuid() {
      this.loadIntegrationOptions();
    },
    campaignType(newVal) {
      console.log(newVal);
      if (newVal === "cleverpush") {
        for (let visual of this.campaign.visuals) {
          visual.type = "neopush";
        }
      }
    },
  },
  computed: {
    filteredWebsites() {
      if (this.campaign.trafficSources.sameTeam) {
        return this.availableWebsites.filter(
          (website) => website.team && website.team.uuid === this.campaign.team
        );
      }
      return this.availableWebsites;
    },
    selectedTeamUuid() {
      return this.campaign.team;
    },
    campaignType() {
      return this.campaign.type;
    },
    cleverPushAvailableForTeam() {
      return this.integrationOptions.integrations.includes("cleverpush");
    },

    formIncomplete() {
      if (!this.campaign.name) {
        return true;
      }

      if (!this.campaign.team) {
        return true;
      }

      if (this.campaign.type === "cleverpush") {
        if (!this.cleverPushAvailableForTeam) {
          return true;
        }

        if (!this.campaign.trafficSources.cleverPushChannel) {
          return true;
        }

        if (
          !this.integrationOptions.cleverPushChannels.find(
            (channel) =>
              channel.id === this.campaign.trafficSources.cleverPushChannel
          )
        ) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    padZero(value: number) {
      return value < 10 ? `0${value}` : value;
    },
    isoDateToDateTimeLocalString(isoDateString: string) {
      const date = new Date(isoDateString);
      return `${date.getFullYear()}-${this.padZero(
        date.getMonth() + 1
      )}-${this.padZero(date.getDate())}T${this.padZero(
        date.getHours()
      )}:${this.padZero(date.getMinutes())}`;
    },
    dateTimeLocalStringToIsoDate(dateTimeLocalString: string) {
      try {
        const [date, time] = dateTimeLocalString.split("T");
        const [year, month, day] = date.split("-");
        const [hour, minute] = time.split(":");
        return new Date(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(day, 10),
          parseInt(hour, 10),
          parseInt(minute, 10)
        ).toISOString();
      } catch (e) {
        return null;
      }
    },
    loadIntegrationOptions() {
      if (!this.selectedTeamUuid) {
        this.loadingIntegrationOptions = false;
        this.integrationOptions = {
          integrations: [],
          cleverPushChannels: [],
        };
        return;
      }

      const fetchingTeamUuid = this.selectedTeamUuid;
      this.loadingIntegrationOptions = true;
      axios
        .get(`/api/campaigns/form-options/${fetchingTeamUuid}`)
        .then((response) => {
          if (this.selectedTeamUuid !== fetchingTeamUuid) {
            return;
          }
          this.integrationOptions = response.data;
          this.loadingIntegrationOptions = false;
        })
        .catch(() => {
          if (this.selectedTeamUuid !== fetchingTeamUuid) {
            return;
          }
          this.$toast.error("Failed to load available integrations");
          this.loadingIntegrationOptions = false;
        });
    },
    async loadCampaign() {
      this.loading = true;
      const response = await axios.get(`/api/campaigns/${this.uuid}`);
      const data = response.data as CreateUpdateCampaignDto;
      data.visuals = (data.visuals || []).map((visual) => {
        visual.key = this.generateKey();
        return visual;
      });
      if (data.startDate) {
        data.startDate = this.isoDateToDateTimeLocalString(data.startDate);
      }
      if (data.endDate) {
        data.endDate = this.isoDateToDateTimeLocalString(data.endDate);
      }

      this.campaign = data;
      this.loading = false;
    },
    copyPlaceholder(placeholder: string) {
      if (!navigator.clipboard) {
        this.$toast.error("Your browser does not support copying to clipboard");
        return;
      }
      navigator.clipboard.writeText("{{" + placeholder + "}}").then(
        () => {
          this.$toast.info("Copied to clipboard");
        },
        () => {
          this.$toast.error(
            "Your browser does not support copying to clipboard"
          );
        }
      );
    },
    addVisual() {
      this.campaign.visuals.push({
        active: true,
        key: this.generateKey(), // a API only key which is used to match the visual to its image / icon files
        type: "neopush",
        title: "",
        body: "",
        url: "",
        icon: "",
        image: "",
        pushubSubId: "",
      });
    },
    removeVisual(index: number) {
      if (this.campaign.visuals.length > index) {
        const visual = this.campaign.visuals[index];
        if (this.imageFile[visual.key]) {
          this.imageFile[visual.key] = null;
          this.imagePreview[visual.key] = null;
        }
        if (this.iconFile[visual.key]) {
          this.iconFile[visual.key] = null;
          this.iconPreview[visual.key] = null;
        }
        this.campaign.visuals.splice(index, 1);
      }
    },

    generateKey() {
      return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      );
    },

    isHourSelected(
      day: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun",
      hour: number
    ) {
      return (
        this.campaign.schedule[day] &&
        this.campaign.schedule[day].includes(hour)
      );
    },

    toggleHour(
      day: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun",
      hour: number
    ) {
      if (!this.campaign.schedule[day]) {
        this.campaign.schedule[day] = [];
      }
      if (this.campaign.schedule[day].includes(hour)) {
        this.campaign.schedule[day] = this.campaign.schedule[day].filter(
          (h) => h !== hour
        );
      } else {
        this.campaign.schedule[day].push(hour);
        this.campaign.schedule[day].sort((a: number, b: number) => a - b);
      }
    },

    schedulePreset(preset: string) {
      switch (preset) {
        case "reset":
          this.campaign.schedule = {
            enabled: this.campaign.schedule.enabled,
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: [],
          };
          break;
        case "mon-fri-8-16":
          this.campaign.schedule = {
            enabled: this.campaign.schedule.enabled,
            mon: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            tue: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            wed: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            thu: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            fri: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            sat: [],
            sun: [],
          };
          break;
        case "mon-fri-17-22":
          this.campaign.schedule = {
            enabled: this.campaign.schedule.enabled,
            mon: [17, 18, 19, 20, 21, 22],
            tue: [17, 18, 19, 20, 21, 22],
            wed: [17, 18, 19, 20, 21, 22],
            thu: [17, 18, 19, 20, 21, 22],
            fri: [17, 18, 19, 20, 21, 22],
            sat: [],
            sun: [],
          };
          break;
        case "mon-sun-10-21":
          this.campaign.schedule = {
            enabled: this.campaign.schedule.enabled,
            mon: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            tue: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            wed: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            thu: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            fri: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            sat: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            sun: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
          };
          break;
      }
    },

    // TODO deduplicate code + reusable upload component (with drag & drop)
    selectIconFile(
      visual: CreateUpdateCampaignDto["visuals"][number],
      event: Event
    ) {
      const files = (event.target as HTMLInputElement).files;
      if (!files || !files.length) {
        return;
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.iconPreview[visual.key] = fileReader.result as string;
      });
      fileReader.readAsDataURL(files[0]);
      this.iconFile[visual.key] = files[0];
      this.removeExistingIcon(visual);
    },

    removeSelectedIconFile(visual: CreateUpdateCampaignDto["visuals"][number]) {
      this.iconFile[visual.key] = null;
      this.iconPreview[visual.key] = null;

      const input = this.$refs[
        `iconFileInput_${visual.key}`
      ] as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },

    removeExistingIcon(visual: CreateUpdateCampaignDto["visuals"][number]) {
      visual.iconUrl = null;
      visual.icon = null;
    },

    selectImageFile(
      visual: CreateUpdateCampaignDto["visuals"][number],
      event: Event
    ) {
      const files = (event.target as HTMLInputElement).files;
      if (!files || !files.length) {
        return;
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imagePreview[visual.key] = fileReader.result as string;
      });
      fileReader.readAsDataURL(files[0]);
      this.imageFile[visual.key] = files[0];
      this.removeExistingImage(visual);
    },

    removeSelectedImageFile(
      visual: CreateUpdateCampaignDto["visuals"][number]
    ) {
      this.imageFile[visual.key] = null;
      this.imagePreview[visual.key] = null;

      const input = this.$refs[
        `imageFileInput_${visual.key}`
      ] as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },

    removeExistingImage(visual: CreateUpdateCampaignDto["visuals"][number]) {
      visual.imageUrl = null;
      visual.image = null;
    },

    submitForm() {
      if (this.saving) {
        return;
      }
      if (this.formIncomplete) {
        return;
      }
      if (!this.campaign.name || this.campaign.name.trim() === "") {
        alert("Please enter a name for your campaign.");
        return;
      }
      if (!this.campaign.team || this.campaign.team.trim() === "") {
        alert("Please select a team for your campaign.");
        return;
      }

      this.saving = true;
      const formData = new FormData();

      for (let visual of this.campaign.visuals || []) {
        const iconFile = this.iconFile[visual.key];
        const imageFile = this.imageFile[visual.key];
        if (iconFile) {
          formData.append(`icon_${visual.key}`, iconFile);
        }
        if (imageFile) {
          formData.append(`image_${visual.key}`, imageFile);
        }
      }

      formData.append(
        "data",
        JSON.stringify({
          ...this.campaign,
          startDate: this.campaign.startDate
            ? this.dateTimeLocalStringToIsoDate(this.campaign.startDate)
            : null,
          endDate: this.campaign.endDate
            ? this.dateTimeLocalStringToIsoDate(this.campaign.endDate)
            : null,
        })
      );
      const request =
        this.mode === "create"
          ? axios.post("/api/campaigns", formData)
          : axios.patch("/api/campaigns/" + this.uuid, formData);
      request
        .then(() => {
          this.saving = false;
          this.$router.push("/campaigns");
        })
        .catch((e) => {
          alert("error saving campaign"); // TODO
          console.log(e);
          this.saving = false;
        });
    },
  },
});
