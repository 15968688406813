import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col gap-2 text-left w-full" }
const _hoisted_2 = { class: "block w-full md:w-1/3 text-xs font-bold text-gray-400" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex justify-between items-center gap-6"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full flex justify-between items-center gap-6"
}
const _hoisted_5 = { class: "text-lg font-bold text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.label), 1),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "edit")
        ]))
      : _createCommentVNode("", true),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, [
            _renderSlot(_ctx.$slots, "display")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}