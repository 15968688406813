<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Disabled Websites </template>
    <template #description>
      Disabled Websites. No notifications will be sent to these. Subscribers
      will still be collected.
    </template>
  </Context>

  <Actions>
    <Input icon-left="search-line" v-model="filters.search" />
    <select v-model="filters.team" class="border border-gray-300 rounded">
      <option value="">All teams</option>
      <option
        v-for="team in availableTeams"
        :value="team.uuid"
        :key="team.uuid"
      >
        {{ team.name }}
      </option>
    </select>
  </Actions>

  <main
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      md:grid-cols-2
      gap-6
      p-6
    "
  >
    <span class="col-span-full text-sm font-bold text-gray-500"
      >Disabled websites</span
    >

    <template v-if="loading"> Loading... </template>
    <template v-if="!loading">
      <template v-for="website in websites" :key="website.uuid">
        <Card>
          <header class="p-4 w-full flex justify-between items-center">
            <div class="text-left">
              <small class="text-xs font-bold text-gray-400">Domain</small>
              <h2 class="text-lg font-bold text-gray-900 truncate">
                {{ website.domain }}
              </h2>
              <h3 class="text-sm font-light text-gray-900">
                {{ website.team?.name }}
              </h3>
            </div>
            <div class="text-right">
              <small class="text-xs font-bold text-gray-400"
                >Subscriptions</small
              >
              <h2 class="text-lg font-bold text-gray-900">
                {{ website.subscription_count }}
              </h2>
            </div>
          </header>
          <footer class="w-full px-4">
            <div
              class="
                w-full
                flex
                px-0
                py-4
                gap-4
                justify-between
                items-center
                overflow-x-auto
              "
            >
              <div class="w-full flex gap-4 justify-end">
                <Button
                  icon="arrow-right-up-line"
                  :to="'/websites/' + website.uuid"
                  >Details</Button
                >
              </div>
            </div>
          </footer>
        </Card>
      </template>
    </template>
  </main>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Breadcrumbs from "@/components/Breadcrumbs";
import Input from "@/components/Input";
import Context from "@/layouts/Context";
import Actions from "@/layouts/Actions";
import debounce from "lodash.debounce";

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Websites", to: "/websites" },
  { name: "Disabled", to: "/websites/disabled" },
];

export default defineComponent({
  name: "Websites.vue",
  components: {
    Card,
    Button,
    Breadcrumbs,
    Input,
    Context,
    Actions,
  },
  props: ["user"],
  data() {
    return {
      loading: true,
      websites: [],
      breadcrumbLinks: breadcrumbLinks,
      codeSnippetVisible: false,
      codeSnippetWebsite: undefined,

      availableTeams: [],
      filters: {
        team: "",
        search: "",
      },
      debouncedLoadWebsites: undefined,
    };
  },
  computed: {},
  async mounted() {
    const { data } = await axios.get("/api/teams");
    this.availableTeams = data;
    await this.loadWebsites();
  },
  created() {
    this.debouncedLoadWebsites = debounce(this.loadWebsites, 250);
  },
  watch: {
    filters: {
      handler() {
        // TODO update URL.
        this.debouncedLoadWebsites();
      },
      deep: true,
    },
  },
  methods: {
    async loadWebsites() {
      this.loading = true;

      const { data } = await axios.get("/api/websites", {
        params: {
          team: this.filters.team,
          search: this.filters.search,
          showDisabled: "1",
        },
      });

      this.websites = data;
      this.loading = false;
    },
  },
});
</script>

<style scoped></style>
