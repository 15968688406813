import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import "remixicon/fonts/remixicon.css";
import "./assets/app.css";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

import VueApexCharts from "vue3-apexcharts";

createApp(App).use(router).use(VueToast).use(VueApexCharts).mount("#app");
