<template>
  <VueMultiselect
    v-model="inputVal"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :placeholder="placeholder"
    :track-by="trackBy"
    :label="label"
  />
</template>
<style scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<script>
import { defineComponent } from "vue";
import VueMultiselect from "vue-multiselect";

export default defineComponent({
  name: "MultiSelect",
  components: {
    VueMultiselect,
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select options",
    },
    trackBy: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
});
</script>
