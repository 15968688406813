
import { defineComponent } from "vue";
import { authStore } from "@/stores/auth.store";
import axios from "axios";
import debounce from "lodash.debounce";
import Button from "@/components/Button.vue";
import Card from "@/components/Card.vue";
import Input from "@/components/Input.vue";
import Spinner from "@/components/Spinner.vue";
import Table from "@/components/Table.vue";
import { ApexOptions } from "apexcharts";
import { AuthPrincipal } from "@/models/auth-principal";
import Context from "@/layouts/Context.vue";
import Actions from "@/layouts/Actions.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

interface WebsiteListDto {
  domain: string;
  team: { name: string; uuid: string };
}

interface DashboardReportResponse {
  totalSubscriptions: number;
  newSubscriptions: number;
  unsubscribes: number;
  sentNotifications: number;
  clicks: number;
  data: {
    day: string;
    newSubscriptions: number;
    unsubscribes: number;
    sentNotifications: number;
    views: number;
    clicks: number;
    conversions: number;
  }[];

  dateFrom: string;
  dateTo: string;
  team: string;
}

function dateToYYYYMMDD(date: Date) {
  const timezoneAdjusted = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );
  return timezoneAdjusted.toISOString().split("T")[0];
}

export default defineComponent({
  name: "Home",
  components: {
    Breadcrumbs,
    Actions,
    Context,
    Button,
    Input,
    Card,
    Spinner,
    Table,
  },
  data() {
    return {
      authState: authStore.state,

      availableWebsites: [] as WebsiteListDto[],
      availableTeams: [] as { name: string; uuid: string }[],

      filters: {
        websiteTeam: "",

        dateFrom: dateToYYYYMMDD(
          new Date(new Date().setDate(new Date().getDate() - 6))
        ),
        dateTo: dateToYYYYMMDD(new Date()),
      },

      showingTeam: "",
      showingDateFrom: "",
      showingDateTo: "",

      loading: true,
      errorLoading: false,
      subsTotal: 0,
      newSubs: 0,
      unsubs: 0,
      sentCount: 0,
      clickCount: 0,
      dayValues: [] as DashboardReportResponse["data"],
      subscriberChart: {
        options: {
          chart: {
            type: "area",
            stacked: false,
          },
          legend: {
            position: "top",
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["rgb(0, 143, 251)", "rgb(200, 150, 50)"],
          labels: [],
          yaxis: [
            {
              title: {
                text: "Subscribers",
              },
            },
          ],
          xaxis: {
            type: "datetime",
          },
        } as ApexOptions,
        series: [] as ApexOptions["series"],
      },
      clickChart: {
        options: {
          chart: {
            type: "area",
            stacked: false,
          },
          legend: {
            position: "top",
          },
          dataLabels: {
            enabled: false,
          },
          labels: [],
          yaxis: [
            {
              title: {
                text: "Clicks",
              },
            },
            {
              opposite: true,
              title: {
                text: "Views",
              },
            },
          ],
          xaxis: {
            type: "datetime",
          },
        } as ApexOptions,
        series: [] as ApexOptions["series"],
      },
      conversionChart: {
        options: {
          chart: {
            type: "area",
            stacked: false,
          },
          legend: {
            position: "top",
          },
          dataLabels: {
            enabled: false,
          },
          labels: [],
          yaxis: [
            {
              title: {
                text: "Conversions",
              },
            },
            {
              opposite: true,
              title: {
                text: "Views",
              },
            },
          ],
          xaxis: {
            type: "datetime",
          },
        } as ApexOptions,
        series: [] as ApexOptions["series"],
      },
    };
  },

  computed: {
    user(): AuthPrincipal | Record<string, string> {
      return this.authState.principal || {};
    },
    filteredWebsites(): WebsiteListDto[] {
      return this.availableWebsites.filter((website: WebsiteListDto) => {
        if (!this.filters.websiteTeam) {
          return true;
        }
        return website.team && website.team.uuid === this.filters.websiteTeam;
      });
    },
  },
  async mounted() {
    const { data: teamData } = await axios.get("/api/teams");
    if (this.user.role == "system_admin") {
      this.availableTeams = teamData;
    } else {
      const userTeamName = this.user.team;
      const userTeam = (teamData as { name: string; uuid: string }[]).filter(
        (team: { name: string; uuid: string }) => team.name == userTeamName
      );
      this.availableTeams = userTeam;
      this.filters.websiteTeam = userTeam[0].uuid;
    }

    await this.loadStatistics();
  },
  watch: {
    filters: {
      handler() {
        this.loadStatisticsDebounced();
      },
      deep: true,
    },
  },
  methods: {
    formatNumber(number: number | string | null) {
      if (!number) {
        return "0";
      }
      if (typeof number === "string") {
        if (isNaN(Number(number))) {
          return number;
        }
        number = +number;
      }
      return new Intl.NumberFormat().format(number);
    },
    loadStatisticsDebounced: debounce(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.loadStatistics();
    }, 500),
    async loadStatistics(force = false) {
      const { dateFrom, dateTo, websiteTeam } = this.filters;

      if (
        dateFrom === this.showingDateFrom &&
        dateTo === this.showingDateTo &&
        websiteTeam === this.showingTeam &&
        !force
      ) {
        return;
      }

      this.loading = true;
      this.errorLoading = false;

      try {
        const { data } = await axios.get<DashboardReportResponse>(
          `/api/reports/dashboard?team=${websiteTeam}&dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        this.subsTotal = data.totalSubscriptions;
        this.newSubs = data.newSubscriptions;
        this.unsubs = data.unsubscribes;
        this.sentCount = data.sentNotifications;
        this.clickCount = data.clicks;

        this.showingDateFrom = data.dateFrom;
        this.showingDateTo = data.dateTo;
        this.showingTeam = data.team;

        this.filters.dateFrom = data.dateFrom;
        this.filters.dateTo = data.dateTo;
        this.filters.websiteTeam = data.team;

        const days = data.data.map((d) => d.day);
        const clickData = data.data.map((d) => d.clicks);
        const subscriberData = data.data.map((d) => d.newSubscriptions);
        const unsubscribeData = data.data.map((d) => d.unsubscribes);
        const viewData = data.data.map((d) => d.views);
        const conversionData = data.data.map((d) => d.conversions);

        this.subscriberChart.options.labels = days;
        this.subscriberChart.series = [
          { name: "Subscribers", type: "area", data: subscriberData },
          { name: "Unsubscribes", type: "line", data: unsubscribeData },
        ];
        this.clickChart.options.labels = days;
        this.clickChart.series = [
          { name: "Clicks", type: "area", data: clickData },
          { name: "Views", type: "line", data: viewData },
        ];
        this.conversionChart.options.labels = days;
        this.conversionChart.series = [
          {
            name: "Conversions",
            type: "area",
            data: conversionData,
          },
          { name: "Views", type: "line", data: viewData },
        ];

        if (data.data.length <= 60) {
          // sort by day descending
          const dayValuesSorted = [...data.data].sort((a, b) => {
            const aDate = new Date(a.day);
            const bDate = new Date(b.day);
            return bDate.getTime() - aDate.getTime();
          });
          this.dayValues = dayValuesSorted;
        } else {
          this.dayValues = [];
        }
      } catch (e) {
        console.error("Error loading statistics", e);
        this.errorLoading = true;
      } finally {
        this.loading = false;
      }
    },
    changePeriod(period: string) {
      if (this.loading) {
        return;
      }
      const now = new Date();
      let dateFrom: Date;
      let dateTo: Date;
      switch (period) {
        case "previous_month":
          dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          dateTo = new Date(now.getFullYear(), now.getMonth(), 0);
          break;
        case "current_month":
          dateFrom = new Date(now.getFullYear(), now.getMonth(), 1);
          dateTo = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          break;
        case "7d":
        default:
          dateFrom = new Date(new Date().setDate(new Date().getDate() - 6));
          dateTo = new Date(new Date().setDate(new Date().getDate()));
          break;
      }

      this.filters.dateFrom = dateToYYYYMMDD(dateFrom);
      this.filters.dateTo = dateToYYYYMMDD(dateTo);
    },
  },
});
