<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Team Details </template>
    <template #description> </template>
  </Context>

  <main
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      gap-6
      p-6
    "
    v-if="!loading && team"
  >
    <span class="col-span-full text-sm font-bold text-gray-500"
      >General Information</span
    >

    <Card>
      <div
        class="
          p-4
          flex flex-col
          md:flex-row
          items-center
          gap-4
          text-left
          w-full
        "
      >
        <small class="block w-full md:w-1/3 text-xs font-bold text-gray-400"
          >Name</small
        >
        <template v-if="generalEdit.active">
          <Input
            v-model="generalEdit.teamNameInput"
            :disabled="generalEdit.saving"
            inline-text
          />
        </template>
        <template v-else>
          <span class="">
            {{ team.name }}
          </span>
        </template>
      </div>

      <div
        class="
          p-4
          flex flex-col
          md:flex-row
          items-center
          gap-4
          text-left
          w-full
        "
      >
        <small class="block w-full md:w-1/3 text-xs font-bold text-gray-400">
          Minimum Subscription Age (Days)
          <small class="block text-gray-300 text-xs">
            Amount of days that a user must be subscribed before receiving push
            notifications.
          </small>
        </small>
        <template v-if="generalEdit.active">
          <Input
            v-model="generalEdit.subscriptionMinimumAgeDaysInput"
            :disabled="generalEdit.saving"
            type="number"
            min="0"
            inline-text
          />
        </template>
        <template v-else>
          <span class="">
            {{ team.subscriptionMinimumAgeDays }}
          </span>
        </template>
      </div>

      <footer
        class="w-full p-4 gap-4 flex justify-end items-center overflow-x-auto"
      >
        <template v-if="generalEdit.active">
          <Button
            :disabled="generalEdit.saving"
            icon="close-line"
            @click="cancelGeneralEdit"
            class="mr-4"
          >
            Cancel
          </Button>
          <Button
            :disabled="generalEdit.saving"
            icon="check-line"
            @click="submitGeneralEdit"
            brand
          >
            Save
          </Button>
        </template>
        <template v-else>
          <Button icon="pencil-line" @click="startGeneralEdit" brand>
            Edit
          </Button>
        </template>
      </footer>
    </Card>

    <span class="col-span-full text-sm font-bold text-gray-500"
      >Integrations</span
    >

    <TeamIntegrations :team-uuid="uuid" />
  </main>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Breadcrumbs from "@/components/Breadcrumbs";
import Input from "@/components/Input";
import Context from "@/layouts/Context";
import TeamIntegrations from "@/views/Teams/TeamIntegrations.vue";

const breadcrumbLinks = [{ name: "Teams", to: "/teams" }];

export default defineComponent({
  name: "TeamDetails",
  components: { TeamIntegrations, Card, Button, Breadcrumbs, Input, Context },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breadcrumbLinks: breadcrumbLinks,
      team: {},

      generalEdit: {
        active: false,
        saving: false,
        teamNameInput: "",
        subscriptionMinimumAgeDaysInput: 0,
      },
    };
  },
  mounted() {
    this.loadTeam();
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
  methods: {
    loadTeam() {
      this.loading = true;

      if (this.breadcrumbLinks.length > 1) {
        this.breadcrumbLinks.splice(-1);
      }

      axios
        .get("/api/teams/" + this.uuid)
        .then((response) => {
          this.team = response.data;
          this.breadcrumbLinks.push({
            name: this.team.name,
            to: "/teams/" + this.team.uuid,
          });

          this.loading = false;
        })
        .catch(() => {
          // TODO
        });
    },

    startGeneralEdit() {
      this.generalEdit.active = true;
      this.generalEdit.teamNameInput = this.team.name;
      this.generalEdit.subscriptionMinimumAgeDaysInput =
        this.team.subscriptionMinimumAgeDays;
    },
    cancelGeneralEdit() {
      if (this.generalEdit.saving) {
        return;
      }
      this.generalEdit.active = false;
    },
    submitGeneralEdit() {
      if (this.generalEdit.saving) {
        return;
      }
      this.generalEdit.saving = true;

      axios
        .patch("/api/teams/" + this.team.uuid, {
          name: this.generalEdit.teamNameInput,
          subscriptionMinimumAgeDays:
            this.generalEdit.subscriptionMinimumAgeDaysInput,
        })
        .then(() => {
          this.team.name = this.generalEdit.teamNameInput;
          this.team.subscriptionMinimumAgeDays =
            this.generalEdit.subscriptionMinimumAgeDaysInput;
          this.generalEdit.active = false;
          this.generalEdit.saving = false;
          this.loadTeam();
        })
        .catch(() => {
          // TODO
        });
    },
  },
});
</script>

<style scoped></style>
