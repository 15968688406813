<template>
  <div>
    <Button icon="bar-chart-2-fill" to="/reports/websites" class="mr-2">
      Websites
    </Button>
    <Button
      icon="bar-chart-2-fill"
      to="/reports/campaigns"
      v-if="user.capabilities.includes('campaigns:read')"
    >
      Campaigns
    </Button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "ReportTabs",
  components: {
    Button,
  },
  props: ["user"],
});
</script>
