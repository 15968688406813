<template>
  <Button
    v-for="(link, linkIndex) in navigationItems"
    :key="linkIndex"
    :icon="link.icon"
    :to="link.disabled ? '' : link.to"
    :disabled="link.disabled"
    :brand="link.current"
    >{{ link.name }}</Button
  >

  <!-- <nav class="space-y-1">
    <router-link
      v-for="item in navigationItems"
      :key="item.name"
      :to="item.disabled ? '' : item.to"
      :class="[
        item.current
          ? 'bg-gray-50 text-orange-600 hover:bg-white'
          : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
        'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
        item.disabled ? 'cursor-not-allowed' : '',
      ]"
      :aria-current="item.current ? 'page' : undefined"
    >
      <component
        :is="item.icon"
        :class="[
          item.current
            ? 'text-orange-500'
            : 'text-gray-400 group-hover:text-gray-500',
          'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
        ]"
        aria-hidden="true"
      />
      <span class="truncate">
        {{ item.name }}
      </span>
    </router-link>
  </nav> -->
</template>

<script>
import { defineComponent } from "vue";
import Button from "@/components/Button";

export default defineComponent({
  name: "WebsiteNav",
  components: {
    Button,
  },
  props: {
    currentPage: {
      type: String,
      required: true,
    },
    user: null,
  },
  computed: {
    navigationItems: function () {
      const uuid = this.$route.params.uuid;
      return [
        {
          name: "General & Setup",
          to: `/websites/${uuid}`,
          icon: "settings-2-line",
          current: this.currentPage === "general",
        },
        {
          name: "Statistics",
          to: "#",
          icon: "line-chart-line",
          current: false,
          disabled: true,
        },
        {
          name: "Subscriptions",
          to: `/websites/${uuid}/subscriptions`,
          icon: "group-line",
          current: this.currentPage === "subscriptions",
        },
        {
          name: "Monetization",
          to: "#",
          icon: "hand-coin-line",
          current: false,
          disabled: true,
        },
        {
          name: "Settings",
          to: `/websites/${uuid}/settings`,
          icon: "settings-2-line",
          current: this.currentPage === "settings",
          disabled: !this.user?.capabilities.includes("websites:manage"),
        },
      ];
    },
  },
});
</script>

<style scoped></style>
