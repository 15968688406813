
import { defineComponent } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default defineComponent({
  name: "ToggleSwitch",
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    offText: {
      type: String,
    },
    onText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateModelValue(value: boolean) {
      if (!this.disabled) {
        this.$emit("update:modelValue", value);
      }
    },
  },
  computed: {
    hasText() {
      return this.offText || this.onText;
    },
    text() {
      return this.modelValue ? this.onText : this.offText;
    },
  },
});
