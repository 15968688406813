import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import CampaignsListPage from "@/views/Campaigns/CampaignsListPage.vue";
import Websites from "@/views/Websites/Websites.vue";
import DisabledWebsites from "@/views/Websites/DisabledWebsites.vue";
import CreateWebsite from "@/views/Websites/CreateWebsite.vue";
import WebsiteDetails from "@/views/Websites/WebsiteDetails.vue";
import WebsiteSettings from "@/views/Websites/WebsiteSettings.vue";
import WebsiteSubscribers from "@/views/Websites/WebsiteSubscribers.vue";
import Error404 from "@/views/Error404.vue";
import AppLayout from "@/AppLayout.vue";
import { authStore } from "@/stores/auth.store";
import CreateCampaign from "@/views/Campaigns/CreateCampaign.vue";
import UpdateCampaign from "@/views/Campaigns/UpdateCampaign.vue";
import WebsiteStatistics from "@/views/Websites/WebsiteStatistics.vue";
import WebsiteReport from "@/views/Reports/Websites.vue";
import CampaignReport from "@/views/Reports/Campaigns.vue";
import TeamsIndex from "@/views/Teams/TeamsIndex.vue";
import CreateTeam from "@/views/Teams/CreateTeam.vue";
import TeamDetails from "@/views/Teams/TeamDetails.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "MainLayout",
    component: AppLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Home,
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },

      {
        path: "/campaigns",
        name: "Campaigns",
        component: CampaignsListPage,
      },
      {
        path: "/campaigns/:uuid/edit",
        name: "Edit Campaign",
        component: UpdateCampaign,
      },
      {
        path: "/campaigns/create",
        name: "Create Campaign",
        component: CreateCampaign,
      },

      {
        path: "/websites",
        name: "Websites",
        component: Websites,
      },
      {
        path: "/websites/disabled",
        name: "Disabled Websites",
        component: DisabledWebsites,
      },
      {
        path: "/website-statistics",
        name: "Website Statistics",
        component: WebsiteStatistics,
      },
      {
        path: "/websites/:uuid/subscriptions",
        name: "Website Subscriptions",
        component: WebsiteSubscribers,
      },
      {
        path: "/websites/:uuid/settings",
        name: "Website Settings",
        component: WebsiteSettings,
      },
      {
        path: "/websites/:uuid",
        name: "Website",
        component: WebsiteDetails,
      },
      {
        path: "/websites/create",
        name: "Create Website",
        component: CreateWebsite,
      },

      {
        path: "/reports/websites",
        name: "Reports: Websites",
        component: WebsiteReport,
      },
      {
        path: "/reports/campaigns",
        name: "Reports: Campaigns",
        component: CampaignReport,
      },

      {
        path: "/teams",
        name: "Teams",
        component: TeamsIndex,
      },
      {
        path: "/teams/:uuid",
        name: "Team",
        component: TeamDetails,
      },
      {
        path: "/teams/create",
        name: "Create Team",
        component: CreateTeam,
      },

      {
        path: "/help",
        name: "Support",
        component: () =>
          import(/* webpackChunkName: "help" */ "../views/Help/HelpIndex.vue"),
      },
      {
        path: "/help/docs-campaigns",
        name: "Campaign Documentation",
        component: () =>
          import(
            /* webpackChunkName: "help" */ "../views/Help/CampaignDocumentation.vue"
          ),
      },
      {
        path: "/help/docs-websites",
        name: "Website Documentation",
        component: () =>
          import(
            /* webpackChunkName: "help" */ "../views/Help/WebsiteDocumentation.vue"
          ),
      },

      { path: "/:pathMatch(.*)*", name: "not-found", component: Error404 },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Redirect to login when trying to access protected routes
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !(await authStore.checkLoggedIn())) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
