
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Badge",

  props: {
    color: {
      type: String as PropType<
        "gray" | "green" | "red" | "yellow" | "blue" | "brand"
      >,
      default: "gray",
    },
  },
});
