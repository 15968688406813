<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Websites </template>
    <template #description>
      Manage your subscribers and monetize your sales. Add a new website to
      download the NeoPush script for your website.
    </template>
  </Context>

  <Actions>
    <Input icon-left="search-line" v-model="filters.search" />
    <select v-model="filters.team" class="border border-gray-300 rounded">
      <option value="">All teams</option>
      <option
        v-for="team in availableTeams"
        :value="team.uuid"
        :key="team.uuid"
      >
        {{ team.name }}
      </option>
    </select>
    <Button
      v-if="user.capabilities.includes('websites:manage')"
      icon="add-line"
      to="/websites/create"
      brand
      >Add website</Button
    >
    <Button icon="line-chart-line" to="/website-statistics" brand
      >Statistics</Button
    >
  </Actions>

  <main
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      md:grid-cols-2
      gap-6
      p-6
    "
  >
    <div class="col-span-full flex">
      <div class="flex-grow">
        <span class="text-sm font-bold text-gray-500"> Active websites </span>
      </div>
      <div class="text-right">
        <router-link
          to="/websites/disabled"
          class="mt-4 text-base font-medium text-brand-500 hover:text-brand-600"
        >
          Show disabled
        </router-link>
      </div>
    </div>
    <!-- <span class="col-span-full text-sm font-bold text-green-500">Bookmarked</span> -->

    <template v-if="loading"> Loading... </template>
    <template v-if="!loading">
      <template v-for="website in websites" :key="website.uuid">
        <Card>
          <header class="p-4 w-full flex justify-between items-center">
            <div class="text-left">
              <small class="text-xs font-bold text-gray-400">Domain</small>
              <h2 class="text-lg font-bold text-gray-900 truncate">
                {{ website.domain }}
              </h2>
              <h3 class="text-sm font-light text-gray-900">
                {{ website.team?.name }}
              </h3>
            </div>
            <div class="text-right">
              <small class="text-xs font-bold text-gray-400"
                >Subscriptions</small
              >
              <h2 class="text-lg font-bold text-gray-900">
                {{ website.subscription_count }}
              </h2>
            </div>
          </header>
          <footer class="w-full px-4">
            <div
              class="
                w-full
                flex
                px-0
                py-4
                gap-4
                justify-between
                items-center
                overflow-x-auto
              "
            >
              <div class="w-full flex gap-4 justify-start items-center"></div>

              <div class="w-full flex gap-4 justify-end">
                <!-- <Button icon="bookmark-3-line">Bookmark</Button> -->
                <Button
                  icon="arrow-right-up-line"
                  :to="'/websites/' + website.uuid"
                  >Details</Button
                >
              </div>
            </div>
          </footer>
        </Card>
      </template>
    </template>
  </main>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Breadcrumbs from "@/components/Breadcrumbs";
import Input from "@/components/Input";
import Context from "@/layouts/Context";
import Actions from "@/layouts/Actions";
import debounce from "lodash.debounce";

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Websites", to: "/websites" },
];

export default defineComponent({
  name: "Websites.vue",
  components: {
    Card,
    Button,
    Breadcrumbs,
    Input,
    Context,
    Actions,
  },
  props: ["user"],
  data() {
    return {
      loading: true,
      websites: [],
      breadcrumbLinks: breadcrumbLinks,
      codeSnippetVisible: false,
      codeSnippetWebsite: undefined,

      availableTeams: [],
      filters: {
        team: "",
        search: "",
      },
      debouncedLoadWebsites: undefined,
    };
  },
  computed: {},
  async mounted() {
    const { data } = await axios.get("/api/teams");
    this.availableTeams = data;
    await this.loadWebsites();
  },
  created() {
    this.debouncedLoadWebsites = debounce(this.loadWebsites, 250);
  },
  watch: {
    filters: {
      handler() {
        // TODO update URL.
        this.debouncedLoadWebsites();
      },
      deep: true,
    },
  },
  methods: {
    async loadWebsites() {
      this.loading = true;

      const { data } = await axios.get("/api/websites", {
        params: {
          team: this.filters.team,
          search: this.filters.search,
        },
      });

      this.websites = data;
      this.loading = false;
    },
  },
});
</script>

<style scoped></style>
