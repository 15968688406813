<template>
  <router-link to="/profile" class="avatar">
    <div class="avatar__text_container">
      <span class="avatar__name">{{ name }}</span>
      <span class="avatar__team" v-if="teamName">{{ teamName }}</span>
    </div>
    <span class="avatar__image">{{ initials }}</span>
  </router-link>
</template>

<style scoped>
.avatar {
  @apply min-w-12 min-h-12 flex-shrink-0 inline-flex gap-3 items-center justify-center;
}
.avatar__image {
  @apply min-w-12 min-h-12 inline-flex items-center justify-center overflow-hidden rounded-xl;
  @apply bg-gray-900 text-white font-semibold;
}
.avatar__name {
  @apply text-sm font-semibold hidden sm:block;
}
.avatar__team {
  @apply text-sm font-light block;
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Avatar",
  props: {
    name: {
      type: String,
      default: null,
    },
    team: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      default: null,
    },
  },
  computed: {
    initials() {
      const name = this.name.split(" ");
      return name[0].charAt(0) + name[1].charAt(0);
    },
    teamName() {
      return this.team || "";
    },
  },
});
</script>
