<template>
  <div
    class="input"
    :class="{
      disabled: disabled,
      '--with-addon-left': hasAddonLeft,
      '--with-addon-right': hasAddonRight,
      '--inline-text': inlineText,
    }"
  >
    <span v-if="hasAddonLeft" class="input__addon">{{ addonLeft }}</span>
    <i v-if="hasIconLeft" class="input__icon" :class="iconClassLeft"></i>
    <input
      class="input__control"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :type="type"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :max="max"
    />
    <i v-if="hasIconRight" class="input__icon" :class="iconClassRight"></i>
    <span v-if="hasAddonRight" class="input__addon">{{ addonRight }}</span>
  </div>
  <!-- <div
    class="input-wrapper"
    :class="{
      disabled: disabled,
      'with-addon-left': hasAddonLeft,
      'with-addon-right': hasAddonRight,
    }"
  >
    <span v-if="hasAddonLeft" class="addon addon-left">
      {{ addonLeft }}
    </span>
    <input
      class="input"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :type="type"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :max="max"
    />
    <span v-if="hasAddonRight" class="addon addon-right">
      {{ addonRight }}
    </span>
  </div> -->
</template>

<style scoped>
.input {
  @apply block relative w-full min-h-12 inline-flex items-center justify-center border rounded-xl overflow-hidden;
  @apply bg-gray-50 border-gray-50 text-gray-900;
}
.input.--inline-text {
  @apply min-h-6 rounded-lg;
}
.input__control {
  @apply w-full min-h-full px-2 border-none outline-none focus:outline-none placeholder-gray-400;
  @apply bg-transparent border-transparent;
  @apply text-base font-normal text-gray-900;
}
.input__addon {
  @apply flex items-center justify-center min-h-full pl-4;
  @apply text-xs font-semibold tracking-wide leading-none;
  @apply text-gray-500 bg-white;
}
.input.--inline-text .input__addon {
  @apply bg-transparent;
}
.input__icon {
  @apply flex items-center justify-center min-h-full px-3;
  @apply text-2xl font-normal;
  @apply text-gray-900;
}
/* .input-wrapper {
  @apply mt-1 flex rounded-md shadow-sm;
}

.input-wrapper .input {
  @apply flex-1
        min-w-0
        block
        w-full
        px-3
        py-2
        rounded-md
        focus:ring-indigo-500 focus:border-indigo-500
        sm:text-sm
        border-gray-300;
}

.input-wrapper.disabled {
  @apply opacity-50;
}

.input-wrapper input:disabled {
  @apply cursor-not-allowed;
}

.input-wrapper.with-addon-left .input {
  @apply rounded-none rounded-r-md;
}
.input-wrapper.with-addon-right .input {
  @apply rounded-none rounded-l-md;
}
.input-wrapper.with-addon-left.with-addon-right .input {
  @apply rounded-none;
}

.input-wrapper .addon {
  @apply inline-flex
  items-center
  px-3
  border border-gray-300
  bg-gray-50
  text-gray-500
  sm:text-sm;
}

.input-wrapper .addon.addon-left {
  @apply rounded-l-md border-r-0;
}

.input-wrapper .addon.addon-right {
  @apply rounded-r-md border-l-0;
} */
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Input",
  props: {
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    addonLeft: {
      type: String,
      default: "",
    },
    addonRight: {
      type: String,
      default: "",
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
    min: {
      type: [String, Number],
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
    inlineText: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  computed: {
    hasAddonLeft: function () {
      return !!this.addonLeft;
    },
    hasAddonRight: function () {
      return !!this.addonRight;
    },
    hasIconLeft: function () {
      return !!this.iconLeft;
    },
    hasIconRight: function () {
      return !!this.iconRight;
    },
    iconClassLeft: function () {
      return this.iconLeft ? `ri-${this.iconLeft}` : null;
    },
    iconClassRight: function () {
      return this.iconRight ? `ri-${this.iconRight}` : null;
    },
  },
});
</script>
