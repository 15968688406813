import { reactive } from "vue";
import { AuthPrincipal } from "@/models/auth-principal";
import axios from "axios";

export const authStore = {
  state: reactive<{
    checkedAuth: boolean;
    loggedIn: boolean;
    principal: AuthPrincipal | undefined;
  }>({
    checkedAuth: false,
    loggedIn: false,
    principal: undefined,
  }),

  setLoggedOut(): void {
    this.state.loggedIn = false;
    this.state.principal = undefined;
  },

  setLoggedIn(authPrincipal: AuthPrincipal): void {
    this.state.checkedAuth = true;
    this.state.loggedIn = true;
    this.state.principal = authPrincipal;
  },

  async checkLoggedIn(): Promise<boolean> {
    if (this.state.checkedAuth) {
      return this.state.loggedIn;
    }
    try {
      const response = await axios.get("/auth/me");
      if (response.status >= 200 && response.status < 300) {
        this.setLoggedIn(response.data);
        return true;
      }
    } catch (e) {
      // not logged in
    }
    this.state.checkedAuth = true;
    return false;
  },
};
