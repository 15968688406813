<template>
  <div id="app__navigation" v-show="navigationState">
    <div id="app__navigation__blackbox" @click="navigationState = false"></div>

    <nav id="app__navigation__container">
      <header id="app__navigation__header">
        <Button icon="close-line" @click="navigationState = false" />
      </header>

      <main id="app__navigation__main">
        <template v-for="(nav, index) in navigation">
          <router-link
            v-if="
              (nav.capability == '' ||
                user.capabilities.includes(nav.capability)) &&
              nav.roles.includes(user.role)
            "
            class="app__navigation__link"
            :to="nav.to"
            :key="index"
          >
            <div class="app__navigation__link__context">
              <span class="app__navigation__link__name">{{ nav.name }}</span>
              <small class="app__navigation__link__desc">{{ nav.desc }}</small>
            </div>

            <i class="app__navigation__link__icon ri-arrow-right-up-line"></i>
          </router-link>
        </template>
      </main>

      <footer id="app__navigation__footer">
        <Button icon="logout-box-line" @click="doLogout" />
      </footer>
    </nav>
  </div>

  <div id="app__topbar">
    <div class="app__topbar__container">
      <Button icon="menu-2-line" @click="navigationState = true">Menu</Button>
      <Logo />
      <Avatar :src="avatarUrl" :name="userName" :team="teamName" />
    </div>
  </div>

  <router-view :user="user"></router-view>
</template>

<style scoped>
#app__navigation {
  @apply w-screen h-screen fixed inset-0 z-50;
}
#app__navigation__blackbox {
  @apply w-screen h-screen;
  @apply bg-black bg-opacity-50;
}
#app__navigation__container {
  @apply w-3/4 max-w-2xl gap-4 fixed inset-2 z-20 rounded-xl shadow-xl flex flex-col;
  @apply bg-white;
}
#app__navigation__header {
  @apply w-full p-4 gap-4 border-b flex items-center justify-between;
  @apply border-gray-50;
}
#app__navigation__main {
  @apply w-full h-full max-h-full px-4 grid grid-rows-max-content md:grid-rows-5 grid-cols-1 md:grid-cols-2 gap-4 overflow-y-auto;
}
#app__navigation__footer {
  @apply w-full p-4 gap-4 border-t flex items-center justify-between;
  @apply border-gray-50;
}
.app__navigation__link {
  @apply p-4 gap-6 flex items-center justify-between rounded-xl;
  @apply bg-white text-gray-900;
  @apply hover:bg-gray-50;
}
.app__navigation__link.router-link-exact-active {
  @apply bg-brand-500 text-white;
}
.app__navigation__link.logout {
  @apply bg-red-50 text-red-900;
}
.app__navigation__link.router-link-active .app__navigation__link__desc {
  @apply opacity-80;
}
.app__navigation__link__context {
  @apply flex flex-col;
}
.app__navigation__link__name {
  @apply text-lg font-semibold;
}
.app__navigation__link__desc {
  @apply text-xs font-normal leading-5 opacity-40;
}
.app__navigation__link__icon {
  @apply text-2xl font-normal;
}
#app__topbar {
  @apply w-full bg-white border-gray-50 border-b;
}
.app__topbar__container {
  @apply w-full max-w-7xl mx-auto p-6 flex items-center justify-between gap-6;
}
</style>

<script>
import { authStore } from "@/stores/auth.store";
import axios from "axios";
import { defineComponent } from "vue";

import Avatar from "@/components/Avatar";
import Button from "@/components/Button";
import Logo from "@/components/Logo";

const navigation = [
  {
    name: "Dashboard",
    desc: "Get an overview of your performance.",
    to: "/",
    exact: true,
    capability: "",
    roles: ["system_admin", "user"],
  },
  {
    name: "Campaigns",
    desc: "Manage your campaigns.",
    to: "/campaigns",
    capability: "campaigns:read",
    roles: ["system_admin", "user"],
  },
  {
    name: "Websites",
    desc: "Manage websites.",
    to: "/websites",
    capability: "websites:read",
    roles: ["system_admin", "user"],
  },
  {
    name: "Reports",
    desc: "Get reports on your performance.",
    to: "/reports/websites",
    capability: "",
    roles: ["system_admin", "user"],
  },
  {
    name: "Teams",
    desc: "Manage teams.",
    to: "/teams",
    capability: "",
    roles: ["system_admin"],
  },
  {
    name: "Support & Documentation",
    desc: "Find information you need to use neopush.",
    to: "/help",
    capability: "",
    roles: ["system_admin"],
  },
];

export default defineComponent({
  name: "AppLayout",
  components: {
    Avatar,
    Button,
    Logo,
  },
  data() {
    return {
      authState: authStore.state,
      navigation: navigation,
      navigationState: false,
      avatarUrl:
        "https://www.gravatar.com/avatar/0bc83cb571cd1c50ba6f3e8a78ef1346",
    };
  },
  watch: {
    $route() {
      this.navigationState = false;
    },
    navigationState: function (newValue) {
      const body = document.body;

      if (newValue) {
        body.classList.add("overflow-hidden");
      } else {
        body.classList.remove("overflow-hidden");
      }
    },
  },
  computed: {
    user() {
      return this.authState.principal || {};
    },
    userName() {
      return this.user.firstName + " " + this.user.lastName;
    },
    userInitials() {
      return this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
    },
    teamName() {
      return this.user.team || "";
    },
  },
  methods: {
    doLogout() {
      axios
        .post("/auth/logout")
        .then(() => {
          authStore.setLoggedOut();
          this.$router.push("/login");
        })
        .catch(() => {
          //TODO error handling...
        });
    },
  },
});
</script>
