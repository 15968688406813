<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="neopush-table-component">
            <slot />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.neopush-table-component {
  @apply min-w-full divide-y divide-gray-200;
}
.neopush-table-component > thead {
  @apply bg-gray-50;
}
.neopush-table-component > thead > tr > th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}
.neopush-table-component > tbody {
  @apply divide-y divide-gray-200;
}
.neopush-table-component > tbody > tr {
  @apply bg-white;
}
.neopush-table-component > tbody > tr > td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
</style>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Table",
});
</script>
