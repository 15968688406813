<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Subscriptions </template>
    <template #description>
      A listing of all your Website subscribers. We offer you additional
      information such as Device, Browser and Server.
    </template>
  </Context>

  <Actions>
    <WebsiteNav current-page="subscriptions" :user="user" />
  </Actions>

  <main
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      md:grid-cols-2
      gap-6
      p-6
      py-28
    "
    v-if="!loading && website && subscriptions.length === 0"
  >
    <div class="col-span-full flex flex-col items-center justify-center">
      <p class="text-sm font-semibold text-brand-500 uppercase tracking-wide">
        We are sorry
      </p>
      <h1 class="mt-2 text-center text-2xl font-bold text-gray-900 sm:text-4xl">
        It looks like you don't have any subscriptions yet.
      </h1>
      <router-link
        to="/websites"
        class="mt-4 text-base font-medium text-brand-500 hover:text-brand-600"
        >Go back to Websites
        <span aria-hidden="true"> &rarr;</span></router-link
      >
    </div>
  </main>

  <main
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      md:grid-cols-2
      gap-6
      p-6
    "
    v-if="loading"
  >
    <div class="w-full bg-gray-100 h-32 rounded-xl animate-pulse">&nbsp;</div>
    <div class="w-full bg-gray-100 h-32 rounded-xl animate-pulse">&nbsp;</div>
    <div class="w-full bg-gray-100 h-32 rounded-xl animate-pulse">&nbsp;</div>
    <div class="w-full bg-gray-100 h-32 rounded-xl animate-pulse">&nbsp;</div>
  </main>

  <main
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      md:grid-cols-2
      gap-6
      p-6
    "
    v-if="!loading && website && subscriptions && subscriptions.length > 0"
  >
    <div class="col-span-full flex items-center justify-between">
      <span class="text-sm font-bold text-gray-500">Website Subscriptions</span>
      <div
        class="
          text-xs
          bg-gray-900
          text-white
          font-semibold
          px-2
          py-1
          rounded-md
        "
      >
        {{ subscriptions.length >= 1000 ? "1000+" : subscriptions.length }}
      </div>
    </div>

    <Card v-for="(subscription, idx) in subscriptions" :key="idx">
      <div class="flex flex-row items-center">
        <div class="p-4 flex flex-col gap-2 text-left w-full">
          <small class="block w-full md:w-1/3 text-xs font-bold text-gray-400"
            >Server</small
          >
          <h2 class="text-xs font-bold text-gray-900">
            {{ subscription.service }}
          </h2>
        </div>

        <div class="text-right pr-6">
          <Badge color="red" v-if="subscription.disabled">Unsubscribed</Badge>
        </div>
      </div>

      <div
        class="
          p-4
          flex flex-row
          items-start
          justify-between
          gap-4
          text-left
          w-full
        "
      >
        <div class="block w-auto">
          <small class="text-left block w-full text-xs font-bold text-gray-400"
            >Device</small
          >
          <h2 class="text-left text-base font-bold text-gray-900">
            {{ subscription.deviceType }}
          </h2>
        </div>
        <div class="block w-auto">
          <small
            class="text-center block w-full text-xs font-bold text-gray-400"
            >Device</small
          >
          <h2 class="text-center text-base font-bold text-gray-900">
            {{ subscription.browser }}
          </h2>
        </div>
        <div class="block w-auto">
          <small class="text-right block w-full text-xs font-bold text-gray-400"
            >Subscribed at</small
          >
          <h2 class="text-right text-base font-bold text-gray-900">
            {{ subscription.createdAt }}
          </h2>
        </div>
      </div>
      <div
        class="
          p-4
          flex flex-row
          items-start
          justify-between
          gap-4
          text-left
          w-full
        "
      >
        <div class="block w-auto">
          <small class="text-left block w-full text-xs font-bold text-gray-400"
            >Language</small
          >
          <h2 class="text-left text-base font-bold text-gray-900">
            {{ subscription.language }}
          </h2>
        </div>
        <div class="block w-auto">
          <small
            class="text-center block w-full text-xs font-bold text-gray-400"
            >Timezone</small
          >
          <h2 class="text-center text-base font-bold text-gray-900">
            {{ subscription.timezone }}
          </h2>
        </div>

        <div class="block w-auto">
          <small class="text-right block w-full text-xs font-bold text-gray-400"
            >Last seen</small
          >
          <h2 class="text-right text-base font-bold text-gray-900">
            {{ subscription.lastSeen }}
          </h2>
        </div>
      </div>
    </Card>
  </main>

  <div class="w-full max-w-7xl mx-auto bg-gray-50 p-6">
    <!-- <Pagination /> -->
    <span v-if="subscriptions && subscriptions.length >= 1000">
      Only showing last 1000 subscriptions
    </span>
  </div>

  <!-- <main class="max-w-7xl mx-auto pb-10 lg:px-8">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <WebsiteNav current-page="subscriptions" />
      </aside>

      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9" v-if="loading">
        Loading...
      </div>

      <div
        class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9"
        v-if="!loading && subscriptions"
      >
        <div class="bg-white shadow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-base leading-6 font-medium text-gray-900">
              Website Subscriptions
              {{ website ? " for " + website.domain : "" }}
            </h3>
            <small class="text-sm">
              Found {{ subscriptions.length }} subscriptions
            </small>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Device
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Browser
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Server
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Subscribed At
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(subscription, idx) in subscriptions"
                          :key="idx"
                          :class="idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ subscription.deviceType }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ subscription.browser }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ subscription.service }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ subscription.createdAt }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main> -->
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import WebsiteNav from "@/views/Websites/WebsiteNav";
import Context from "@/layouts/Context";
import Actions from "@/layouts/Actions";
import Card from "@/components/Card";
import Breadcrumbs from "@/components/Breadcrumbs";
import Badge from "@/components/Badge";

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Websites", to: "/websites" },
];

export default defineComponent({
  name: "WebsiteSubscribers",
  components: {
    Badge,
    WebsiteNav,
    Context,
    Actions,
    Card,
    Breadcrumbs,
  },
  props: ["user"],
  data() {
    return {
      subscriptions: null,
      website: null,
      loading: true,
      breadcrumbLinks: breadcrumbLinks,
    };
  },

  mounted() {
    this.loadWebsite();
  },

  methods: {
    loadWebsite() {
      this.loading = true;
      this.website = null;
      this.subscriptions = null;

      if (this.breadcrumbLinks.length > 2) {
        this.breadcrumbLinks.splice(-1);
      }

      axios
        .get("/api/websites/" + this.uuid + "/subscriptions")
        .then((response) => {
          this.subscriptions = response.data;
          this.loading = false;
        })
        .catch(() => {
          // TODO
        });
      axios
        .get("/api/websites/" + this.uuid)
        .then((response) => {
          this.website = response.data;
          this.breadcrumbLinks.push({
            name: this.website.domain,
            to: "/websites/" + this.website.uuid,
          });
        })
        .catch(() => {
          // TODO
        });
    },
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
});
</script>

<style scoped></style>
