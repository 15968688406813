
import { defineComponent } from "vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "ImageWithLightbox",
  components: { Button, Modal },

  props: {
    thumbnailClass: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    src: {
      required: true,
    },
  },

  data() {
    return {
      lightboxOpen: false,
    };
  },

  methods: {
    openLightbox() {
      this.lightboxOpen = true;
    },
    closeLightbox() {
      this.lightboxOpen = false;
    },
  },
});
