<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Add team </template>
    <template #description> </template>
  </Context>

  <form
    @submit.prevent="submitForm(false)"
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      gap-6
      p-6
    "
  >
    <span class="col-span-full text-sm font-bold text-gray-500"
      >Team details</span
    >

    <Card>
      <div
        class="
          p-4
          flex flex-col
          md:flex-row
          items-center
          gap-4
          text-left
          w-full
        "
      >
        <small class="block w-full md:w-1/3 text-xs font-bold text-gray-400"
          >Name</small
        >
        <Input v-model="teamName" :disabled="saving" inline-text />
      </div>

      <footer
        class="w-full p-4 gap-4 flex justify-end items-center overflow-x-auto"
      >
        <Button icon="close-line" to="/teams">Cancel</Button>
        <Button type="submit" icon="add-line" brand>Add</Button>
      </footer>
    </Card>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Breadcrumbs from "@/components/Breadcrumbs";
import Input from "@/components/Input";
import Context from "@/layouts/Context";

const breadcrumbLinks = [
  { name: "Teams", to: "/teams" },
  { name: "Create team", to: "/teams/create" },
];

export default defineComponent({
  name: "CreateTeam",
  components: { Card, Button, Breadcrumbs, Input, Context },
  data() {
    return {
      teamName: "",
      saving: false,
      breadcrumbLinks: breadcrumbLinks,
    };
  },
  methods: {
    submitForm() {
      if (this.saving) {
        return;
      }
      if (this.teamName.length === 0) {
        alert("Please enter a name");
        return;
      }

      this.saving = true;
      axios
        .post("/api/teams", {
          name: this.teamName,
        })
        .then(() => {
          this.saving = false;
          this.$router.push("/teams");
        })
        .catch((e) => {
          alert("error creating team"); // TODO
          console.log(e, e.response);
          this.saving = false;
        });
    },
  },
});
</script>

<style scoped></style>
