<template>
  <div style="" class="h-full">
    <div class="flex h-full flex-col">
      <div
        ref="container"
        class="isolate flex flex-auto flex-col overflow-auto bg-white"
      >
        <div
          style="width: 165%"
          class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div
            ref="containerNav"
            class="
              sticky
              top-0
              z-30
              flex-none
              bg-white
              shadow
              ring-1 ring-black ring-opacity-5
              sm:pr-8
            "
          >
            <div
              class="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden"
            >
              <button
                v-for="day in days"
                :key="day"
                type="button"
                class="flex flex-col items-center pb-3 pt-2"
              >
                <span
                  :class="
                    'mt-1 flex h-8 w-8 items-center justify-center text-gray-900 ' +
                    (day === 'Wed' ? 'font-semibold' : '')
                  "
                >
                  {{ day }}
                </span>
              </button>
            </div>

            <div
              class="
                -mr-px
                hidden
                grid-cols-7
                divide-x divide-gray-100
                border-r border-gray-100
                text-sm
                leading-6
                text-gray-500
                sm:grid
              "
            >
              <div class="col-end-1 w-14" />

              <div
                class="flex items-center justify-center py-3"
                v-for="day in days"
                :key="day"
              >
                <span>
                  <span
                    :class="
                      'items-center justify-center text-gray-900 ' +
                      (day === 'Wed' ? 'font-semibold' : '')
                    "
                    >{{ day }}</span
                  ></span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-auto">
            <div
              class="
                sticky
                left-0
                z-10
                w-14
                flex-none
                bg-white
                ring-1 ring-gray-100
              "
            />
            <div class="grid flex-auto grid-cols-1 grid-rows-1">
              <!-- Horizontal lines -->
              <div
                class="
                  col-start-1 col-end-2
                  row-start-1
                  grid
                  divide-y divide-gray-100
                "
                style="grid-template-rows: repeat(48, minmax(3.5rem, 1fr))"
              >
                <div ref="containerOffset" class="row-end-1 h-7" />
                <template v-for="hour in hours" :key="hour">
                  <div>
                    <div
                      class="
                        sticky
                        left-0
                        z-20
                        -ml-14
                        -mt-2.5
                        w-14
                        pr-2
                        text-right text-xs
                        leading-5
                        text-gray-400
                      "
                    >
                      {{ hour }}
                    </div>
                  </div>
                  <div />
                </template>
              </div>

              <!-- Vertical lines -->
              <div
                class="
                  col-start-1 col-end-2
                  row-start-1
                  hidden
                  grid-cols-7 grid-rows-1
                  divide-x divide-gray-100
                  sm:grid sm:grid-cols-7
                "
              >
                <div class="col-start-1 row-span-full" />
                <div class="col-start-2 row-span-full" />
                <div class="col-start-3 row-span-full" />
                <div class="col-start-4 row-span-full" />
                <div class="col-start-5 row-span-full" />
                <div class="col-start-6 row-span-full" />
                <div class="col-start-7 row-span-full" />
                <div class="col-start-8 row-span-full w-8" />
              </div>

              <!-- Events -->
              <ol
                class="
                  col-start-1 col-end-2
                  row-start-1
                  grid grid-cols-1
                  sm:grid-cols-7 sm:pr-8
                "
                style="
                  grid-template-rows: 1.75rem repeat(288, minmax(0, 1fr)) auto;
                "
              >
                <!-- defines height for other grid cells -->
                <li
                  class="relative mt-px flex sm:col-start-1"
                  style="grid-row: 2 / span 6"
                >
                  <div class="h-36"></div>
                </li>

                <li
                  :class="`relative mt-px flex sm:col-start-${slot.column}`"
                  :style="`grid-row: ${slot.row} / span 6`"
                  v-for="slot in calendarSlots"
                  :key="slot.key"
                >
                  <div class="inset-1 absolute overflow-y-auto">
                    <router-link
                      v-for="item in slot.items"
                      :key="item.uuid"
                      class="
                        rounded-lg
                        bg-gray-200
                        hover:bg-gray-300
                        text-xs
                        p-2
                        block
                        mb-2
                      "
                      :to="`/campaigns/${item.uuid}/edit`"
                    >
                      <span
                        v-if="item.status === 'active'"
                        class="
                          inline-block
                          w-1.5
                          h-1.5
                          bg-green-500
                          rounded-full
                        "
                      ></span>
                      <span
                        v-else-if="item.status === 'inactive'"
                        class="inline-block w-1.5 h-1.5 bg-red-500 rounded-full"
                      ></span>
                      <span
                        v-else-if="item.status === 'ended'"
                        class="inline-block w-1.5 h-1.5 bg-red-500 rounded-full"
                      ></span>
                      <span
                        v-else-if="item.status === 'scheduled'"
                        class="
                          inline-block
                          w-1.5
                          h-1.5
                          bg-yellow-500
                          rounded-full
                        "
                      ></span>

                      {{ item.name }}
                    </router-link>
                  </div>
                </li>
              </ol>

              <!-- Current time horizontal line -->
              <div
                class="
                  col-start-1 col-end-2
                  row-start-1
                  grid
                  divide-y divide-blue-500
                  opacity-75
                  pointer-events-none
                "
                style="
                  grid-template-rows: 1.75rem repeat(1440, minmax(0, 1fr)) auto;
                "
              >
                <hr
                  class="relative border-blue-600 border-1 border-dashed"
                  :style="currentTimeLineStyle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "CampaignWeekView",
  components: {},
  props: {
    campaigns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],

      currentMinuteOfDay: 0,
      updateTimeInterval: null,
    };
  },
  computed: {
    currentTimeLineStyle() {
      const offset = 2;
      return "grid-row: " + (this.currentMinuteOfDay + offset);
    },
    calendarSlots() {
      const items = [];
      const slots = {};
      for (let campaign of this.campaigns) {
        const days = Object.keys(campaign.schedule);
        for (let day of days) {
          const dayIndex = this.days.indexOf(day);
          if (dayIndex === -1) {
            continue;
          }
          const daySlots = campaign.schedule[day];
          for (let slot of daySlots) {
            const slotTime =
              typeof slot === "number"
                ? (slot < 10 ? "0" + slot : slot) + ":00"
                : slot;
            const slotKey = dayIndex + "-" + slotTime;

            if (!slotTime.includes(":")) {
              continue;
            }

            const hour = parseInt(slotTime.split(":")[0], 10);
            const minutePart = parseInt(slotTime.split(":")[1], 10);

            if (!slots[slotKey]) {
              slots[slotKey] = {
                key: slotKey,
                column: dayIndex + 1,
                row: hour * 12 + 2 + (minutePart === 30 ? 6 : 0),
                items: [],
              };
            }
            slots[slotKey].items.push(campaign);
            items.push({
              uuid: campaign.uuid,
              name: campaign.name,
              column: dayIndex + 1,
              row: hour * 12 + 2 + (minutePart === 30 ? 6 : 0),
            });
          }
        }
      }
      return Object.values(slots);
    },
  },
  mounted() {
    // initial scroll to current time
    this.fetchServerTime().then(({ hour, minute }) => {
      this.currentMinuteOfDay = hour * 60 + minute;
      this.scrollToHour(hour);
    });

    // periodically update position of current time indicator
    const updateCurrentTime = () => {
      this.fetchServerTime().then(({ hour, minute }) => {
        this.currentMinuteOfDay = hour * 60 + minute;
      });
    };

    this.updateTimeInterval = setInterval(updateCurrentTime, 30000);
  },
  beforeUnmount() {
    if (this.updateTimeInterval !== null) {
      clearInterval(this.updateTimeInterval);
    }
  },
  methods: {
    scrollToHour(hour) {
      const currentHourFirstMinute = hour * 60;
      const container = this.$refs.container;
      const containerNav = this.$refs.containerNav;
      const containerOffset = this.$refs.containerOffset;
      container.scrollTop =
        ((container.scrollHeight -
          containerNav.offsetHeight -
          containerOffset.offsetHeight) *
          currentHourFirstMinute) /
        1440;
    },

    async fetchServerTime() {
      const serverTimeResponse = await axios.get("/api/reports/server-time");

      return {
        hour: serverTimeResponse.data.hour,
        minute: serverTimeResponse.data.minute,
      };
    },
  },
});
</script>
