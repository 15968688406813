
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormRow",
  props: {
    label: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
});
