<template>
  <nav class="app__actions">
    <div class="app__actions__container">
      <slot />
    </div>
  </nav>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Actions",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
});
</script>

<style scoped>
.app__actions {
  @apply w-full sticky z-10 top-0 py-1 rounded-2xl rounded-t-none shadow-xl;
  @apply bg-white;
}
.app__actions__container {
  @apply w-full max-w-7xl mx-auto py-5 px-6 flex justify-between items-center gap-6 overflow-x-auto overscroll-x-none;
}
</style>
