<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumbs :links="breadcrumbLinks" />
    </template>
    <template #title> Settings </template>
    <template #description>
      Advanced settings for your website. Set up a welcome notification, specify
      how visitors should be prompted for a subscription and more.
    </template>
  </Context>

  <Actions>
    <WebsiteNav current-page="settings" :user="user" />
  </Actions>

  <form
    @submit.prevent="submitForm"
    class="
      w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      gap-6
      p-6
    "
  >
    <div class="text-right">
      <Button
        v-if="!editMode && user.capabilities.includes('websites:manage')"
        icon="edit-line"
        @click="enableEditMode"
        brand
      >
        Edit
      </Button>
      <Button v-if="editMode" icon="close-line" @click="cancelEdit">
        Cancel
      </Button>
      <Button
        class="ml-4"
        type="submit"
        v-if="editMode"
        icon="check-line"
        brand
      >
        Save
      </Button>
    </div>

    <span class="col-span-full text-sm font-bold text-gray-500"
      >Subscription Prompt</span
    >
    <Card>
      <FormRow label="Type" :edit-mode="editMode">
        <template #edit>
          <select v-model="formData.promptType">
            <option value="native">Native</option>
            <option value="html_slide_down">HTML Slide Down</option>
          </select>
        </template>
        <template #display>
          {{ formData.promptType }}
        </template>
      </FormRow>

      <FormRow label="Trigger Mode" :edit-mode="editMode">
        <template #edit>
          <select v-model="formData.promptTriggerMode">
            <option value="automatic">Automatic</option>
            <option value="manual">Manual</option>
          </select>
        </template>
        <template #display>
          {{ formData.promptTriggerMode }}
        </template>
      </FormRow>

      <FormRow
        label="Trigger minimum page views"
        :edit-mode="editMode"
        v-if="formData.promptTriggerMode === 'automatic'"
      >
        <template #edit>
          <Input v-model="formData.promptMinPageViews" type="number" />
        </template>
        <template #display>
          {{ formData.promptMinPageViews }}
        </template>
      </FormRow>

      <template v-if="formData.promptType === 'html_slide_down'">
        <FormRow label="Prompt Text" :edit-mode="editMode">
          <template #edit>
            <Input v-model="formData.promptConfiguration.text" />
          </template>
          <template #display>
            {{ formData.promptConfiguration.text }}
          </template>
        </FormRow>

        <FormRow label="Confirm Button Text" :edit-mode="editMode">
          <template #edit>
            <Input v-model="formData.promptConfiguration.confirmButtonText" />
          </template>
          <template #display>
            {{ formData.promptConfiguration.confirmButtonText }}
          </template>
        </FormRow>

        <FormRow label="Cancel Button Text" :edit-mode="editMode">
          <template #edit>
            <Input v-model="formData.promptConfiguration.cancelButtonText" />
          </template>
          <template #display>
            {{ formData.promptConfiguration.cancelButtonText }}
          </template>
        </FormRow>

        <FormRow label="Confirm Button Color" :edit-mode="editMode">
          <template #edit>
            <div class="flex items-center w-full">
              <div class="flex-grow">
                <Input
                  v-model="formData.promptConfiguration.confirmButtonColor"
                />
              </div>
              <div
                class="inline-block h-6 w-6 rounded ml-4"
                :style="{
                  backgroundColor:
                    formData.promptConfiguration.confirmButtonColor,
                }"
              ></div>
            </div>
          </template>
          <template #display>
            <div class="flex items-center w-full">
              <div class="flex-grow">
                {{ formData.promptConfiguration.confirmButtonColor }}
              </div>
              <div
                class="inline-block h-6 w-6 rounded ml-4"
                :style="{
                  backgroundColor:
                    formData.promptConfiguration.confirmButtonColor,
                }"
              ></div>
            </div>
          </template>
        </FormRow>

        <FormRow label="Cancel Button Color" :edit-mode="editMode">
          <template #edit>
            <div class="flex items-center w-full">
              <div class="flex-grow">
                <Input
                  v-model="formData.promptConfiguration.cancelButtonColor"
                />
              </div>
              <div
                class="inline-block h-6 w-6 rounded ml-4"
                :style="{
                  backgroundColor:
                    formData.promptConfiguration.cancelButtonColor,
                }"
              ></div>
            </div>
          </template>
          <template #display>
            <div class="flex items-center w-full">
              <div class="flex-grow">
                {{ formData.promptConfiguration.cancelButtonColor }}
              </div>
              <div
                class="inline-block h-6 w-6 rounded ml-4"
                :style="{
                  backgroundColor:
                    formData.promptConfiguration.cancelButtonColor,
                }"
              ></div>
            </div>
          </template>
        </FormRow>
      </template>
    </Card>

    <span class="col-span-full text-sm font-bold text-gray-500">
      Notification Settings
    </span>

    <Card>
      <FormRow
        label="Close old notifications when a new one is received"
        :edit-mode="editMode"
      >
        <template #edit>
          <ToggleSwitch
            v-model="formData.deliverySettings.closeOld"
            on-text="Yes"
            off-text="No"
          />
        </template>
        <template #display>
          {{ formData.deliverySettings.closeOld ? "Yes" : "No" }}
        </template>
      </FormRow>

      <template v-if="formData.deliverySettings.closeOld">
        <FormRow label="Close notifications older than " :edit-mode="editMode">
          <template #edit>
            <Input
              type="number"
              v-model.number="formData.deliverySettings.closeOldMinutes"
              addon-right=" minutes"
            />
          </template>
          <template #display>
            {{ formData.deliverySettings.closeOldMinutes }} minutes
          </template>
        </FormRow>
      </template>
    </Card>

    <span class="col-span-full text-sm font-bold text-gray-500">
      Welcome Notification
    </span>

    <Card>
      <FormRow label="Enabled" :edit-mode="editMode">
        <template #edit>
          <ToggleSwitch
            v-model="formData.welcomeNotification.enabled"
            on-text="Yes"
            off-text="No"
          />
        </template>
        <template #display>
          {{ formData.welcomeNotification.enabled ? "Yes" : "No" }}
        </template>
      </FormRow>

      <template v-if="formData.welcomeNotification.enabled">
        <FormRow label="Title" :edit-mode="editMode">
          <template #edit>
            <Input v-model="formData.welcomeNotification.title" />
          </template>
          <template #display>
            {{ formData.welcomeNotification.title }}
          </template>
        </FormRow>

        <FormRow label="Body" :edit-mode="editMode">
          <template #edit>
            <Input v-model="formData.welcomeNotification.body" />
          </template>
          <template #display>
            {{ formData.welcomeNotification.body }}
          </template>
        </FormRow>

        <FormRow label="URL" :edit-mode="editMode">
          <template #edit>
            <Input v-model="formData.welcomeNotification.url" />
          </template>
          <template #display>
            {{ formData.welcomeNotification.url }}
          </template>
        </FormRow>

        <FormRow label="Icon" :edit-mode="editMode">
          <template #edit>
            <div class="flex items-center w-full">
              <div class="w-1/2">
                <!-- Upload Icon file -->
                <input
                  type="file"
                  accept="image/*"
                  ref="iconFileInput"
                  @change="selectIconFile"
                  :disabled="submitting"
                />
              </div>
              <div class="w-1/2">
                <div
                  v-if="
                    !iconPreview &&
                    formData.welcomeNotification &&
                    formData.welcomeNotification.iconUrl
                  "
                >
                  <ImageWithLightbox
                    alt="Current Welcome Icon"
                    :src="formData.welcomeNotification.iconUrl"
                    thumbnail-class="w-1/5 h-auto rounded-lg"
                  />
                  <Button
                    @click="removeExistingIcon"
                    :disabled="submitting"
                    icon="close-line"
                  >
                    Remove
                  </Button>
                </div>
                <div v-if="iconPreview">
                  <ImageWithLightbox
                    alt="Icon Upload preview"
                    :src="iconPreview"
                    thumbnail-class="w-1/5 h-auto rounded-lg"
                  />
                  <Button
                    @click="removeSelectedIconFile"
                    :disabled="submitting"
                    icon="close-line"
                    >Remove</Button
                  >
                </div>
              </div>
            </div>
          </template>
          <template #display>
            <div class="w-full">
              <ImageWithLightbox
                v-if="formData.welcomeNotification.iconUrl"
                alt="Welcome Icon"
                :src="formData.welcomeNotification.iconUrl"
                thumbnail-class="w-1/4 h-auto"
              />
            </div>
          </template>
        </FormRow>
        <FormRow label="Image" :edit-mode="editMode">
          <template #edit>
            <div class="flex items-center w-full">
              <div class="w-1/2">
                <!-- Upload Image file -->
                <input
                  type="file"
                  accept="image/*"
                  ref="imageFileInput"
                  @change="selectImageFile"
                  :disabled="submitting"
                />
              </div>
              <div class="w-1/2">
                <div
                  v-if="
                    !imagePreview &&
                    formData.welcomeNotification &&
                    formData.welcomeNotification.imageUrl
                  "
                >
                  <ImageWithLightbox
                    alt="Current Welcome Image"
                    :src="formData.welcomeNotification.imageUrl"
                    thumbnail-class="w-1/5 h-auto rounded-lg"
                  />
                  <Button
                    @click="removeExistingImage"
                    :disabled="submitting"
                    icon="close-line"
                  >
                    Remove
                  </Button>
                </div>
                <div v-if="imagePreview">
                  <ImageWithLightbox
                    alt="Image Upload preview"
                    :src="imagePreview"
                    thumbnail-class="w-1/5 h-auto rounded-lg"
                  />
                  <Button
                    @click="removeSelectedImageFile"
                    :disabled="submitting"
                    icon="close-line"
                    >Remove</Button
                  >
                </div>
              </div>
            </div>
          </template>
          <template #display>
            <div class="w-full">
              <ImageWithLightbox
                v-if="formData.welcomeNotification.imageUrl"
                alt="Welcome Icon"
                :src="formData.welcomeNotification.imageUrl"
                thumbnail-class="w-1/4 h-auto"
              />
            </div>
          </template>
        </FormRow>
      </template>
    </Card>

    <span class="col-span-full text-sm font-bold text-gray-500">
      Apple Push Network
    </span>

    <Card>
      <FormRow label="Status" :edit-mode="editMode">
        <template #edit>
          <ToggleSwitch
            v-model="formData.apn.enabled"
            on-text="Enabled"
            off-text="Disabled"
            disabled
          />
          <em><i class="ri-information-fill" /> Not supported yet</em>
        </template>
        <template #display>
          {{ formData.apn.enabled ? "Enabled" : "Disabled" }}
        </template>
      </FormRow>
    </Card>

    <div class="text-right">
      <Button
        v-if="!editMode && user.capabilities.includes('websites:manage')"
        icon="edit-line"
        @click="enableEditMode"
        brand
      >
        Edit
      </Button>
      <Button v-if="editMode" icon="close-line" @click="cancelEdit">
        Cancel
      </Button>
      <Button
        class="ml-4"
        type="submit"
        v-if="editMode"
        icon="check-line"
        brand
      >
        Save
      </Button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import WebsiteNav from "@/views/Websites/WebsiteNav";
import Input from "../../components/Input";
import Button from "@/components/Button";

import Context from "@/layouts/Context";
import Actions from "@/layouts/Actions";
import Card from "@/components/Card";
import Breadcrumbs from "@/components/Breadcrumbs";
import ToggleSwitch from "@/components/ToggleSwitch";
import FormRow from "@/components/FormRow";
import ImageWithLightbox from "@/components/ImageWithLightbox.vue";

const breadcrumbLinks = [
  { name: "Dashboard", to: "/" },
  { name: "Websites", to: "/websites" },
];

export default defineComponent({
  name: "WebsiteSettings",
  components: {
    ImageWithLightbox,
    FormRow,
    ToggleSwitch,
    Button,
    Input,
    WebsiteNav,
    Context,
    Actions,
    Card,
    Breadcrumbs,
  },
  props: ["user"],
  data() {
    return {
      website: null,
      loading: true,
      breadcrumbLinks: breadcrumbLinks,
      submitting: false,
      editMode: false,

      websiteSettings: {},
      iconPreview: null,
      iconFile: null,
      imagePreview: null,
      imageFile: null,
      formData: {
        promptType: "",
        promptTriggerMode: "",
        promptMinPageViews: 0,
        promptConfiguration: {
          text: "",
          confirmButtonText: "",
          cancelButtonText: "",
          confirmButtonColor: "",
          cancelButtonColor: "",
        },

        welcomeNotification: {
          enabled: false,
          title: "",
          body: "",
          url: "",
          icon: "",
          image: "",

          iconUrl: null,
          imageUrl: null,
        },

        deliverySettings: {
          closeOld: false,
          closeOldMinutes: 90,
        },

        apn: {
          enabled: false,
        },
      },
    };
  },

  mounted() {
    this.loadWebsite();
    this.loadSettings();
  },

  methods: {
    loadSettings() {
      this.loading = true;
      this.websiteSettings = null;
      axios
        .get(`/api/websites/${this.uuid}/settings`)
        .then((response) => {
          this.websiteSettings = response.data;
          this.formData = JSON.parse(JSON.stringify(this.websiteSettings));
          this.loading = false;
        })
        .catch((e) => {
          console.error(e);
          // TODO
        });
    },
    loadWebsite() {
      this.website = null;

      if (this.breadcrumbLinks.length > 2) {
        this.breadcrumbLinks.splice(-1);
      }

      axios
        .get("/api/websites/" + this.uuid)
        .then((response) => {
          this.website = response.data;
          this.breadcrumbLinks.push({
            name: this.website.domain,
            to: "/websites/" + this.website.uuid,
          });
        })
        .catch(() => {
          // TODO
        });
    },

    enableEditMode() {
      this.formData = JSON.parse(JSON.stringify(this.websiteSettings)); // create deep copy - can be replaced with lodash
      this.editMode = true;
    },

    cancelEdit() {
      this.formData = JSON.parse(JSON.stringify(this.websiteSettings));
      this.editMode = false;
    },

    selectIconFile(event) {
      const files = event.target.files;
      if (!files.length) {
        return;
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.iconPreview = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.iconFile = files[0];
      this.removeExistingIcon();
    },
    removeSelectedIconFile() {
      this.iconFile = null;
      this.iconPreview = null;
      if (this.$refs.iconFileInput) {
        this.$refs.iconFileInput.value = "";
      }
    },

    removeExistingIcon() {
      this.formData.welcomeNotification.iconUrl = null;
      this.formData.welcomeNotification.icon = null;
    },

    selectImageFile(event) {
      const files = event.target.files;
      if (!files.length) {
        return;
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imagePreview = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.imageFile = files[0];
      this.removeExistingImage();
    },
    removeSelectedImageFile() {
      this.imageFile = null;
      this.imagePreview = null;
      if (this.$refs.imageFileInput) {
        this.$refs.imageFileInput.value = "";
      }
    },

    removeExistingImage() {
      this.formData.welcomeNotification.imageUrl = null;
      this.formData.welcomeNotification.image = null;
    },

    submitForm() {
      this.submitting = true;
      const formData = new FormData();
      if (this.iconFile) {
        formData.append("welcome_icon", this.iconFile);
      }
      if (this.imageFile) {
        formData.append("welcome_image", this.imageFile);
      }
      // add json form data
      formData.append("data", JSON.stringify(this.formData));
      axios
        .patch(`/api/websites/${this.uuid}/settings`, formData)
        .then((response) => {
          this.submitting = false;
          this.websiteSettings = response.data;
          this.formData = JSON.parse(JSON.stringify(this.websiteSettings));
          this.editMode = false;

          // clean up upload temp vars
          this.removeSelectedIconFile();
          this.removeSelectedImageFile();
        })
        .catch((e) => {
          console.log(e);
          alert("Error saving settings, check logs for details");
        });
    },
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
});
</script>

<style scoped></style>
