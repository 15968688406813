<template>
  <svg
    class="logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    data-name="Gruppe 128"
    viewBox="0 0 40 40"
  >
    <defs>
      <path id="a" fill="none" d="M0 0h40v40H0z" data-name="Rechteck 16" />
    </defs>
    <defs>
      <clipPath id="b">
        <use xlink:href="#a" data-name="Rechteck 16" />
      </clipPath>
      <clipPath id="c">
        <use xlink:href="#a" data-name="Rechteck 14" />
      </clipPath>
      <clipPath id="d">
        <path
          fill="none"
          d="M13.333 0A13.334 13.334 0 0 0 0 13.333V40l26.667-26.667A13.334 13.334 0 0 0 13.333 0Z"
          data-name="Pfad 25"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#b)" data-name="Gruppe 30">
      <g data-name="Gruppe 27">
        <g clip-path="url(#c)" data-name="Gruppe 26">
          <path
            fill="#202a44"
            d="M13.337 26.667a13.334 13.334 0 0 0 26.667 0V0Z"
            data-name="Pfad 24"
          />
        </g>
      </g>
      <g data-name="Gruppe 29">
        <g clip-path="url(#d)" data-name="Gruppe 28">
          <image
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAmCAYAAABDClKtAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAJaADAAQAAAABAAAAJgAAAAD0oFnlAAABt0lEQVRYCc3YIUzEMBQG4O0CAoJGo9FoLBoQCMQZsGDBosGCPRyQ4LBoNBqNxpEQSMb/Frq0vbbX9r12NGnavkvf+267XZo1Xdc1En3lbg9pZHJNGoG2er/fURo1clOyUTbEXucAWSgAvlC0v0p6cS4sG4XCH4B8/3VRWBYKoHcNJA5LRgH05gCJwpJQAL0GQGKwaBRALxEgEVgUCqDnBBAbthAF0BNAPxkowmU9lUEUQA8aphrMiwJopoHULakCc6IAunaAqsHmUABdBkBVYAYKoIsIUHHYgALoLAFUFNajADrJABWDtTgxHgG0LNCXMnO02Gc0SkTfeMxGF8SATT4PHm8RVLeCO7L/x+Bp+9/Uf4ERiG7Z8PSNDUP9wTJMSDgWDHXXqL5qBoqCtWGot64wapxD1YQBtKEg+uhE1YABtKlD9LkXVRIG0JaOsOdBVAkYQNs2wl4vREnCANqxAa51FEoCBtCuC+CKRaM4MIAOXcV9sSRUDgygY19xXzwZlQID6NRXOBTPQsXAADoPFQ591tIrQU7DqXWK/cYhEaArTk42iorrMIBuOKB+r9TLUxyrp1K5fgEvP4PsOV48jwAAAABJRU5ErkJggg=="
            width="67.614"
            height="69.442"
            data-name="Rechteck 15"
            transform="translate(-20.102 -14.822)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.logo {
  @apply w-12 h-12 flex-shrink-0 inline-flex gap-3 items-center justify-center;
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Logo",
  props: {},
  computed: {},
});
</script>
