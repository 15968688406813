
import { defineComponent } from "vue";
import CampaignCreateUpdateForm from "@/views/Campaigns/CampaignCreateUpdateForm.vue";

export default defineComponent({
  name: "UpdateCampaign",
  components: { CampaignCreateUpdateForm },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
});
