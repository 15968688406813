<template>
  <header class="app__context" :class="{ '--minimal': actionsExsits }">
    <div class="app__context__container">
      <ul class="app__context__breadcrumbs">
        <slot name="breadcrumbs" />
      </ul>

      <h1 class="app__context__title">
        <slot name="title" />
      </h1>

      <p class="app__context__description">
        <slot name="description" />
      </p>
    </div>
  </header>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Context",
  components: {},
  data() {
    return {
      actionsExsits: null,
    };
  },
  mounted() {
    var actions = document.querySelector(".app__actions");
    this.actionsExsits = actions ? true : false;
  },
  methods: {},
  computed: {},
});
</script>

<style scoped>
.app__context {
  @apply w-full relative z-10 rounded-2xl rounded-t-none shadow-xl;
  @apply bg-white;
}
.app__context__container {
  @apply w-full max-w-7xl mx-auto p-6 flex flex-col gap-3;
}
.app__context.--minimal {
  @apply shadow-none rounded-none;
}
.app__context.--minimal .app__context__container {
  @apply pb-0;
}
.app__context__breadcrumbs {
  @apply mb-3;
}
.app__context__title {
  @apply text-3xl md:text-4xl font-bold text-gray-900;
}
.app__context__description {
  @apply text-gray-500 font-normal text-sm leading-6;
}
</style>
