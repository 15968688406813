<template>
  <div
    :class="[{ 'flex items-center justify-center': !inline, inline: inline }]"
  >
    <i
      :class="[
        'ri-loader-5-line',
        'text-opacity-50',
        'animate-spin',
        'inline-block',
        'text-' + size,
      ]"
    ></i>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "5xl",
    },
  },
};
</script>

<style scoped></style>
