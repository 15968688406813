<template>
  <router-view v-if="initialized" />
  <!-- Show loading spinner while initializing -->
  <div
    class="min-h-screen flex flex-col items-center justify-center bg-gray-50"
    v-else
  >
    <div>
      <svg
        class="animate-spin h-8 w-8 text-blue-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <div class="mt-4">Loading...</div>
  </div>

  <!-- Portal for toast notifications -->
</template>

<style></style>

<script>
import { authStore } from "@/stores/auth.store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  created() {
    authStore
      .checkLoggedIn()
      .then(() => {
        // do nothing, just ensure auth state is retrieved
      })
      .catch(() => {
        // user is not logged in
      });
  },

  data() {
    return {
      authState: authStore.state,
    };
  },
  computed: {
    initialized() {
      return this.authState.checkedAuth;
    },
  },
});
</script>
