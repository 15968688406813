
import { defineComponent } from "vue";
import axios, { AxiosResponse } from "axios";
import { authStore } from "@/stores/auth.store";
import { AuthPrincipal } from "@/models/auth-principal";
import Logo from "@/components/Logo.vue";

export default defineComponent({
  name: "Home",
  components: { Logo },

  data() {
    return {
      loading: false,
      principal: undefined,
      error: undefined,
      email: "",
      password: "",
    };
  },

  created() {
    if (authStore.state.loggedIn) {
      this.$router.push("/");
    }
  },

  methods: {
    submitLogin() {
      this.loading = true;
      this.error = undefined;
      axios
        .post<
          { email: string; password: string },
          AxiosResponse<AuthPrincipal>
        >("/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          authStore.setLoggedIn(response.data);
          this.$router.push("/");
        })
        .catch((e) => {
          this.error = e.message;
          throw e;
        })
        .finally(() => (this.loading = false));
    },
  },
});
