<template>
  <Context>
    <template #breadcrumbs>
      <Breadcrumb
        :links="[
          { name: 'Dashboard', to: '/' },
          { name: 'Campaigns', to: '/campaigns' },
        ]"
      />
    </template>
    <template #title> Campaigns </template>
    <template #description> Manage your campaigns. </template>
  </Context>
  <Actions>
    <Input icon-left="search-line" v-model="filters.search" />
    <select v-model="filters.status" class="border border-gray-300 rounded">
      <option value="">All</option>
      <option value="active">Active</option>
      <option value="inactive">Disabled</option>
    </select>
    <select v-model="filters.team" class="border border-gray-300 rounded">
      <option value="">All teams</option>
      <option
        v-for="team in availableTeams"
        :value="team.uuid"
        :key="team.uuid"
      >
        {{ team.name }}
      </option>
    </select>

    <div class="flex-grow px-2">
      <small class="block w-full text-xs font-bold text-gray-400">
        Date from
      </small>
      <Input v-model="filters.dateFrom" type="date" />
    </div>
    <div class="flex-grow px-2">
      <small class="block w-full text-xs font-bold text-gray-400">
        Date to
      </small>
      <Input v-model="filters.dateTo" type="date" />
    </div>

    <Button icon="add-line" to="/campaigns/create" brand>Add Campaign</Button>

    <Button icon="calendar-line" @click="openCalendarModal" brand></Button>
  </Actions>
  <main
    class="
      w-full w-full
      max-w-7xl
      mx-auto
      min-h-full
      bg-gray-50
      grid grid-cols-1
      gap-6
      p-6
    "
  >
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Sent</th>
          <th>Impressions</th>
          <th>Clicks</th>
          <th>CTR</th>
          <th>Conversions</th>
          <th>CVR</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="campaign in campaigns" :key="campaign.uuid">
          <td>
            <router-link
              class="
                text-brand-600
                hover:text-brand-500
                break-all
                whitespace-normal
              "
              :to="`/campaigns/${campaign.uuid}/edit`"
              >{{ campaign.name }}</router-link
            >
            <br />
            <small class="text-gray-600">{{ campaign.team.name }}</small>
          </td>
          <td>
            <Badge color="green" v-if="campaign.status === 'active'">
              Active
            </Badge>
            <Badge color="red" v-else-if="campaign.status === 'inactive'">
              Disabled
            </Badge>
            <Badge color="red" v-else-if="campaign.status === 'ended'">
              Ended
            </Badge>
            <Badge color="yellow" v-else-if="campaign.status === 'scheduled'">
              Scheduled
            </Badge>
            <Badge color="gray" v-else>
              {{ campaign.status }}
            </Badge>
          </td>
          <td>{{ campaign.sentCount }}</td>
          <td>{{ campaign.impressionCount }}</td>
          <td>{{ campaign.clickCount }}</td>
          <td>
            {{
              campaign.impressionCount > 0
                ? formatPercentage(
                    campaign.clickCount / campaign.impressionCount
                  )
                : " - "
            }}
          </td>
          <td>{{ campaign.conversionCount }}</td>
          <td>
            {{
              campaign.clickCount > 0
                ? formatPercentage(
                    campaign.conversionCount / campaign.clickCount
                  )
                : " - "
            }}
          </td>
          <td>
            <Button icon="edit-line" :to="`/campaigns/${campaign.uuid}/edit`">
              Edit
            </Button>
            <Button icon="file-copy-line" @click="cloneCampaign(campaign.uuid)">Clone</Button>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="8">
            <div class="text-center">Loading ...</div>
          </td>
        </tr>
      </tbody>
    </Table>

    <LargeModal title="Calendar" :open="calendarModalOpen">
      <template #content>
        <CampaignWeekView :campaigns="campaigns" />
      </template>
      <template #buttons>
        <Button @click="closeCalendarModal">Close</Button>
      </template>
    </LargeModal>
  </main>
</template>

<script>
import Context from "../../layouts/Context";
import Breadcrumb from "../../components/Breadcrumbs";
import Actions from "../../layouts/Actions";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { defineComponent } from "vue";
import Table from "@/components/Table";
import Badge from "@/components/Badge";
import axios from "axios";
import debounce from "lodash.debounce";
import CampaignWeekView from "@/views/Campaigns/CampaignWeekView.vue";
import LargeModal from "@/components/LargeModal.vue";

export default defineComponent({
  name: "CampaignsListPage",
  components: {
    LargeModal,
    CampaignWeekView,
    Badge,
    Table,
    Button,
    Input,
    Actions,
    Breadcrumb,
    Context,
  },

  data() {
    return {
      campaigns: [],
      loading: false,

      availableTeams: [],
      filters: {
        team: "",
        search: "",
        dateFrom: new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .split("T")[0],
        dateTo: new Date().toISOString().split("T")[0],
        status: "active",
      },

      debouncedLoadCampaigns: undefined,

      calendarModalOpen: false,
    };
  },
  async mounted() {
    const { data } = await axios.get("/api/teams");
    this.availableTeams = data;
    await this.loadCampaigns();
  },
  created() {
    this.debouncedLoadCampaigns = debounce(this.loadCampaigns, 250);
  },
  watch: {
    filters: {
      handler() {
        this.debouncedLoadCampaigns();
      },
      deep: true,
    },
  },
  methods: {
    async cloneCampaign(campaignUuid) {
      try {
        this.loading = true;
        const { data } = await axios.post(
          `/api/campaigns/${campaignUuid}/clone`
        );
        this.loading = false;

        // Redirect to the edit page of the newly cloned campaign
        await this.$router.push(`/campaigns/${data.uuid}/edit`);
      } catch (error) {
        console.error("Error cloning campaign:", error);
        this.loading = false;
      }
    },
    async loadCampaigns() {
      this.loading = true;

      const { data } = await axios.get("/api/campaigns", {
        params: {
          team: this.filters.team,
          search: this.filters.search,
          dateFrom: this.filters.dateFrom,
          dateTo: this.filters.dateTo,
          status: this.filters.status,
        },
      });

      this.campaigns = data;
      this.loading = false;
    },
    formatPercentage(value) {
      if (!value) return "0%";
      return `${(value * 100).toFixed(2)} %`;
    },

    openCalendarModal() {
      this.calendarModalOpen = true;
    },
    closeCalendarModal() {
      this.calendarModalOpen = false;
    },
  },
});
</script>

<style scoped></style>
